import "../LoginView/LoginView.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import util_logo from "../../Assets/Images/logo_fundo_claro.png";
import loginBackground from "../../Assets/Images/loginBackground2.png";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import AuthService from "../../Services/AuthService";
import paths from "../../router/paths";
const ResetPasswordView = (props: any) => {
  const parametros = useParams();
  const { t } = useTranslation(["passwordReset"]);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  return (
    <div
      className="loginPage"
      style={{
        backgroundImage: `url(${loginBackground}) `,
        backgroundSize: "cover",
      }}
    >
      <div className="content">
        <img src={util_logo} alt="Logo Util" className="utilLogo" />

        <form
          onSubmit={() => {
            parametros.resetToken &&
              AuthService.resetPassword(password,password, parametros.resetToken);
            navigate(paths.LOGIN_SCREEN);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            gap: "10px",
            padding: "50px 0px",
          }}
        >
          <TextField
            required
            label={t("passwordReset:passwordField")}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <Button
            fullWidth
            style={{
              marginTop: "10px",
              backgroundColor: "#124251",
              color: "#f4f7fe",
              padding: "10px 30px",
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Reset
          </Button>
        </form>
      </div>
    </div>
  );
};
export default ResetPasswordView;
