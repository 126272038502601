import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewSensorForm.css";

interface newSensorInterface {
  name: string;
  number: number;
  deviceId: string;

  dataType: string;

  alertDetail: boolean;

  variable: string;
  resolution: number;
  precedence: number;
  variableType: string;

  sensor_limits: string;
  sensor_limits_min_value: number | null;
  sensor_limits_max_value: number | null;
  sensor_limits_fixed_value: number | null;

  operation_limits: string;
  operation_limits_min_value: number | null;
  operation_limits_max_value: number | null;
  operation_limits_fixed_value: number | null;
}

const initialState: newSensorInterface = {
  name: "",
  number: -1,
  deviceId: "",
  dataType: "",
  // type: "",
  alertDetail: false,
  variable: "",
  // unit: "",
  resolution: 0.0,
  precedence: -1,
  variableType: "",
  sensor_limits: "",
  sensor_limits_min_value: null,
  sensor_limits_max_value: null,
  sensor_limits_fixed_value: null,
  operation_limits: "",
  operation_limits_min_value: null,
  operation_limits_max_value: null,
  operation_limits_fixed_value: null,
};

const NewSensorForm = (props: any) => {
  const [newSensor, setNewSensor] = useState<newSensorInterface>(initialState);
  const [types, setTypes] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);
  const [newType, setNewType] = useState(false);
  const [newTypeName, setNewTypeName] = useState<string | null>(null);
  const [newUnit, setNewUnit] = useState(false);
  const [newUnitName, setNewUnitName] = useState<string | null>(null);
  const [newUnitAbbreviation, setNewUnitAbbreviation] = useState<string | null>(
    null
  );
  const [selectedTypeId, setSelectedTypeId] = useState<number>(0);
  const [selectedUnitId, setSelectedUnitId] = useState<number>(0);

  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    axios.get(`/types`).then((response) => {
      setTypes(response.data);
    });
  }, []);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setNewSensor({ ...newSensor, [name]: value });
  };

  const onChangeCheckbox = (event: any) => {
    const { name, checked } = event.target;
    setNewSensor({ ...newSensor, [name]: checked });
  };

  const onChangeType = (event: any) => {
    if (event.target.value === "test") {
      setNewType(true);
      setUnits([]);
    } else {
      setNewType(false);
      setSelectedTypeId(event.target.value);
      const aux = types.find(
        (element) => element.id === parseInt(event.target.value)
      );
      setUnits(aux.units);
    }
  };

  const onChangeUnit = (event: any) => {
    if (event.target.value === "test") {
      setNewUnit(true);
    } else {
      setNewUnit(false);
      setSelectedUnitId(event.target.value);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    let associate = false;
    let type_id: number = 0;
    let unit_id: number = 0;
    if (newType) {
      const response = await axios.post("/types", { name: newTypeName });
      associate = true;
      type_id = response.data[0].id;
    } else {
      type_id = selectedTypeId;
    }
    if (newUnit) {
      const response = await axios.post("/units", {
        name: newUnitName,
        abbreviation: newUnitAbbreviation,
      });
      unit_id = response.data[0].id;
      associate = true;
    } else {
      unit_id = selectedUnitId;
    }
    if (associate) {
      await axios.post("/types/associate", { type_id, unit_id });
    }

    const response = await DbService.store_sensor(
      props.digitalTwinId,
      newSensor.name,
      Date.now(),
      newSensor.number,
      newSensor.deviceId,
      newSensor.dataType,
      newSensor.alertDetail,
      newSensor.variable,
      type_id,
      unit_id,
      // newSensor.type,
      // newSensor.unit,
      newSensor.resolution,
      newSensor.precedence,
      newSensor.variableType,
      newSensor.sensor_limits,
      newSensor.sensor_limits_min_value,
      newSensor.sensor_limits_max_value,
      newSensor.sensor_limits_fixed_value,
      newSensor.operation_limits,
      newSensor.operation_limits_min_value,
      newSensor.operation_limits_max_value,
      newSensor.operation_limits_fixed_value
    );
    setLoadingRequest(false);
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  const customStyle = {
    marginRight: "5px",
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "calc(100vh - 520px)",
      }}
    >
      <div
        className="inputs"
        style={{ overflowY: "auto" }}
      >
        <div className="form-div-input">
          <input
            id="name"
            name="name"
            type="text"
            onChange={onChange}
            placeholder="Digite a tag do sensor"
          />
          <label htmlFor="name">Tag</label>
        </div>
        <div className="form-div-input">
          <input
            id="number"
            name="number"
            type="number"
            onChange={onChange}
            placeholder="Digite o número do sensor"
          />
          <label htmlFor="number">Numero</label>
        </div>

        <div className="form-div-input">
          <input
            id="deviceId"
            name="deviceId"
            type="text"
            onChange={onChange}
            placeholder="Digite o device id"
          />
          <label htmlFor="deviceId">deviceId</label>
        </div>

        <div style={{ marginTop: "13px" }}>
          <input
            id="alertDetail"
            name="alertDetail"
            type="checkbox"
            onChange={onChangeCheckbox}
          />
          <label htmlFor="alertDetail">
            Mostrar no Cenário de Recomendações
          </label>
        </div>

        <div className="form-div-select">
          <select id="dataType" name="dataType" onChange={onChange} required>
            <option value="" disabled selected hidden>
              Selecione o dataType
            </option>

            <option value={"float"}>float</option>
            <option value={"int"}>int</option>
            <option value={"string"}>string</option>
            <option value={"boolean"}>boolean</option>
          </select>
          <label htmlFor="dataType">dataType</label>
        </div>

        <div className="form-div-select">
          <select
            id="variableType"
            name="variableType"
            onChange={onChangeType}
            required
          >
            <option value="" disabled selected hidden>
              Selecione o tipo de variavel
            </option>
            {types.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
            <option value={"test"}>novo tipo de variavel</option>
          </select>
          <label htmlFor="variableType">Tipo</label>
        </div>

        {newType && (
          <div className="form-div-input">
            <input
              id="novoTipo"
              name="variable"
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewTypeName(event.target.value);
              }}
              placeholder="Digite o nome do tipo"
            />
            <label htmlFor="novoTipo">novoTipo</label>
          </div>
        )}

        <div className="form-div-select">
          <select
            id="variableType"
            name="variableType"
            onChange={onChangeUnit}
            required
          >
            <option value="" disabled selected hidden>
              Selecione a unidade da váriavel
            </option>
            {units.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}({element.abbreviation})
              </option>
            ))}
            <option value={"test"}>nova unidade</option>
          </select>
          <label htmlFor="unitNew">Unidade</label>
        </div>

        {newUnit && (
          <ul>
            <div className="form-div-input">
              <input
                id="novoTipo"
                name="variable"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewUnitName(event.target.value);
                }}
                placeholder="Digite o nome do tipo"
              />
              <label htmlFor="novoTipo">nome da unidade</label>
            </div>

            <div className="form-div-input">
              <input
                id="novoTipo"
                name="variable"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewUnitAbbreviation(event.target.value);
                }}
                placeholder="Digite o nome do tipo"
              />
              <label htmlFor="novoTipo">sigla da unidade</label>
            </div>
          </ul>
        )}

        <ul>
          <div className="form-div-input">
            <input
              id="variable"
              name="variable"
              type="text"
              onChange={onChange}
              placeholder="Digite o nome da variável"
            />
            <label htmlFor="variable">Nome</label>
          </div>
          <div className="double">
            <div className="form-div-input" style={customStyle}>
              <input
                id="resolution"
                name="resolution"
                type="number"
                onChange={onChange}
                placeholder="0"
                step="1"
              />
              <label htmlFor="resolution">Casas Decimais</label>
            </div>
            <div className="form-div-input">
              <input
                id="precedence"
                name="precedence"
                type="number"
                min="0"
                onChange={onChange}
                placeholder="Digite o grau de precedência"
              />
              <label htmlFor="precedence">Precedência de Aparição</label>
            </div>
          </div>
          <div className="form-div-select">
            <select
              id="variableType"
              name="variableType"
              onChange={onChange}
              required
            >
              <option value="" disabled selected hidden>
                Selecione o tipo de variavel
              </option>
              <option value="control">Controle</option>
              <option value="monitoring">Monitoramento</option>
              <option value="kpi">KPI</option>
            </select>
            <label htmlFor="type">Tipo de Variavel</label>
          </div>

          <div className="form-div-select">
            <select
              id="sensor_limits"
              name="sensor_limits"
              onChange={onChange}
              required
            >
              <option value="" disabled selected hidden>
                Selecione o tipo de regra da variavel
              </option>

              <option value="min_max">Valor maximo e minimo</option>
              <option value="min">Valor minimo</option>
              <option value="max">Valor maximo</option>
              <option value="fixed">Valor Fixo</option>
              <option value="none">Nenhuma</option>
            </select>
            <label htmlFor="type">Limites do Sensor</label>
          </div>
          <ul>
            {(newSensor.sensor_limits === "min" ||
              newSensor.sensor_limits === "min_max") && (
              <div className="form-div-input">
                <input
                  id="sensor_limits_min_value"
                  name="sensor_limits_min_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="minValue">Valor minimo</label>
              </div>
            )}
            {(newSensor.sensor_limits === "max" ||
              newSensor.sensor_limits === "min_max") && (
              <div className="form-div-input">
                <input
                  id="sensor_limits_max_value"
                  name="sensor_limits_max_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="maxValue">Valor maximo</label>
              </div>
            )}
            {newSensor.sensor_limits === "fixed" && (
              <div className="form-div-input">
                <input
                  id="sensor_limits_fixed_value"
                  name="sensor_limits_fixed_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="fixedValue">Valor fixo</label>
              </div>
            )}
          </ul>

          <div className="form-div-select">
            <select
              id="operation_limits"
              name="operation_limits"
              onChange={onChange}
              required
            >
              <option value="" disabled selected hidden>
                Limites de Processo
              </option>

              <option value="min_max">Valor maximo e minimo</option>
              <option value="min">Valor minimo</option>
              <option value="max">Valor maximo</option>
              <option value="fixed">Valor Fixo</option>
              <option value="none">Nenhuma</option>
            </select>
            <label htmlFor="type">Limites de Processo</label>
          </div>
          <ul>
            {(newSensor.operation_limits === "min" ||
              newSensor.operation_limits === "min_max") && (
              <div className="form-div-input">
                <input
                  id="operation_limits_min_value"
                  name="operation_limits_min_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="operation_limits_min_value">Valor minimo</label>
              </div>
            )}
            {(newSensor.operation_limits === "max" ||
              newSensor.operation_limits === "min_max") && (
              <div className="form-div-input">
                <input
                  id="operation_limits_max_value"
                  name="operation_limits_max_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="operation_limits_max_value">Valor maximo</label>
              </div>
            )}
            {newSensor.operation_limits === "fixed" && (
              <div className="form-div-input">
                <input
                  id="operation_limits_fixed_value"
                  name="operation_limits_fixed_value"
                  type="number"
                  onChange={onChange}
                />
                <label htmlFor="operation_limits_fixed_value">Valor fixo</label>
              </div>
            )}
          </ul>
        </ul>
      </div>

      <div
        style={{
         
          display: "flex",
          justifyContent: "flex-end",
          alignSelf: "end",
          
        }}
      >
        <Button
          onClick={props.cancel}
          style={{
            backgroundColor: "#FFF",
            color: "#124251",
            marginRight: "10px",
          }}
          variant="contained"
        >
          Cancelar
        </Button>
        {loadingRequest ? (
          <Button
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            <CircularProgress style={{ color: "white" }} size={20} />
          </Button>
        ) : (
          <Button
            type="submit"
            style={{
              backgroundColor: "#124251",
            }}
            variant="contained"
          >
            Salvar Sensor
          </Button>
        )}
      </div>
    </form>
  );
};
export default NewSensorForm;
