import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface DigitalTwin {
  id: string;
  name: string;
  status: string;
}

interface DigitalTwinsList {
  data: {
    [companyId: string]: Array<DigitalTwin>;
  };
}

const initialState: DigitalTwinsList = {
  data: {},
};

// Define the async thunk
export const setDigitalTwinsListAsync = createAsyncThunk(
  "digitalTwinsList/setDigitalTwinsListAsync",
  async (payload: { companyId: any; payload: any }, { getState }) => {
    const state: any = getState();
    const companyId = state.company.data.id;

    return { companyId, payload };
  }
);

export const digitalTwinsListSlice = createSlice({
  name: "digitalTwinsList",
  initialState,
  reducers: {
    resetDigitalTwinsList: (state) => {
      state.data = {};
    },
    setDigitalTwinsList : (state, action) => {
      const { companyId, payload } = action.payload;
      state.data[companyId] = payload;
    },
  },
  
});

export const getDigitalTwinsList = (state) => {
  const companyId = state.company.data.id;
  return state.digitalTwinsList.data[companyId] || [];
};
export const getDigitalTwinsListLength = (state) => {
  const companyId = state.company.data.id;
  return state.digitalTwinsList.data[companyId].length || 0;
};

export const getIsDigitalTwinsListDefined = (state) => {
  const companyId = state.company.data.id;
  return state.digitalTwinsList.data[companyId]?.length > 0;
};

export const { resetDigitalTwinsList,setDigitalTwinsList } = digitalTwinsListSlice.actions;
export default digitalTwinsListSlice.reducer;
