import { createSlice } from "@reduxjs/toolkit";

interface Company {
  data: {
    id: string;
    name: string;
    complement: string;
  };
}

const initialState: Company = {
  data: {
    id: "",
    name: "",
    complement: "",
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.data = action.payload;
    },
    resetCompany: (state) => {
      state.data = { ...initialState.data };
    },
  },
});

export const getCompany = (state) => state.company.data;
export const getCompanyId = (state) => state.company.data.id;
export const getIsCompanyDefined = (state) => state.company.data.id !== "";


export const getCompanyList = (state) => state.companies.data;

export const { setCompany, resetCompany } = companySlice.actions;
export default companySlice.reducer;
