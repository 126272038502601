import { useTheme } from "@mui/material";
import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";
import "./SensorCard.css";

const SensorCard = (props: any) => {
  let variableType: string;
  let color: string;

  // console.log(props.sensor)
  switch (props.sensor.variable_type) {
    case "kpi":
      variableType = "KPI";
      color = "#F48117";
      break;
    case "monitoring":
      variableType = "monitoramento";
      color = "#C4C4C4";
      break;
    case "control":
      variableType = "controle";
      color = "#009E73";
      break;
    default:
      variableType = "error";
      color = "#FFF";
      break;
  }
 const theme = useTheme();
  return (
    <div className="SensorCard">
      <div>
        <div className="line">
          <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>{props.sensor.name} </p>
          <p  className="variableType" style={{ backgroundColor: color, }}>
            {variableType}
          </p>
        </div>
        <div className="line">
          <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>{props.sensor.variable}</p>
          <p className="unit" style={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>({props.sensor.unit.abbreviation})</p>
        </div>
      </div>
      <div onClick={props.onClick} className="icon">
        <Icon />
      </div>
    </div>
  );
};
export default SensorCard;
