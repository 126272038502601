import { Box, CircularProgress, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataService from "../../Services/DataService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as Ok } from "../../Assets/Icons/Ok.svg";
import { ReactComponent as Alarm } from "../../Assets/Icons/Alarm.svg";
import { ReactComponent as Alert } from "../../Assets/Icons/Alert.svg";
import {
  getDigitalTwinEffiencyInterval,
  getDigitalTwinId,
} from "../../store/slices/digitalTwinSlice";

import { getDb } from "../../store/slices/dbSlice";



import ReferenceValue from "../Barometer/ReferenceValue";
import { useTheme } from "@mui/material/styles";

interface CardVelocimetroProps {
  elemento: any;
  h: number | null;
  w: number | null;

}

const CardVelocimetro = ({ elemento, h, w }: CardVelocimetroProps) => {
  // const [loading] = useState(false);
  const [isDigitalTwinOn, setIsDigitalTwinOn] = useState(false);
  const digitalTwinId = useSelector(getDigitalTwinId);
  const db = useSelector(getDb);
  const efficiencyInterval = useSelector(getDigitalTwinEffiencyInterval);

  const [value, setValue] = useState<any>();
  const [secondaryValues, setSecondaryValues] = useState<any>([]);
  const [status, setStatus] = useState("");
  const [range, setRange] = useState<number[]>(efficiencyInterval);
  const PROPORTION = 2 / 3;

  useEffect(() => {
    const fetchData = async () => {
      const response = await DataService.getDigitalTwinEfficiencyInterval(
        digitalTwinId
      );
      setRange(response.data);
    };
    // Set a timeout to delay the first fetch by 5 minutes
    const timeoutId = setTimeout(() => {
      fetchData();
      // After the first execution, set an interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000);

      // Clear the interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => clearTimeout(timeoutId);
  }, [digitalTwinId]);

  const fetchData = useCallback(
    async (digitalTwinId) => {
      DataService.getTwins().then((res1) => {
        const response = res1
          .filter((twin) => twin.id === digitalTwinId)
          .at(-1);

        response && setRange(response.efficiency_interval);
        response && setStatus(response.status);

        elemento.speedormeter_secondary.forEach((sensor) => {
          response &&
            NewDi2winDataService.getLastNInfo(
              db.dbName,
              db.collection,
              sensor.number,
              sensor.device_id,
              elemento.time,
              elemento.time_range,
              "1"
            ).then((res) => {
              res.at(-1)?.value !== undefined && setSecondaryValues((prev: any) => [
                ...prev,
                {
                  [sensor.number]: res.at(-1).value !== undefined 
                },
              ]);
            });
        });

        DataService.getsensor(elemento.x_axis_sensor_id_bar).then((res) => {
          response &&
            NewDi2winDataService.getLastNInfo(
              db.dbName,
              db.collection,
              res.number,
              res.device_id,
              elemento.time,
              elemento.time_range,
              "1"
            ).then((res) => {
              setIsDigitalTwinOn(res.at(-1).value);
            });
        });

        response &&
          NewDi2winDataService.getLastNInfo(
            db.dbName,
            db.collection,
            elemento.speedometer_main.number,
            elemento.speedometer_main.device_id,
            elemento.time,
            elemento.time_range,
            "1"
          ).then((res) => {
            res.length !== 0 && setValue(res.at(-1).value);
          });
      });
    },
    [elemento, db]
  );

  useEffect(() => {
    fetchData(digitalTwinId);

    const interval = setInterval(() => {
      fetchData(digitalTwinId);
    }, 10000);

    return () => clearInterval(interval);
  }, [digitalTwinId, fetchData]);

  const [started, setStarted] = useState(false);

  const theme = useTheme();  

  const option = {
    backgroundColor: "transparent",

    grid: {
      top: 0,
      left: 50,
      right: 50,
      bottom: 0,
      containLabel: true,
    },
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)) *
            1.5,
        splitNumber: {
          show: false,
        },
        animationDuration: 5000,
        axisLine: {
          lineStyle: {
            width: -6,

            color: [
              [PROPORTION, "#118751"],
              [1, "#c04040"],
            ],
          },
        },
        pointer: {
          show: false,
          length: "10%",
          width: value === 0 ? 0 : 5,
          itemStyle: {},
        },
        axisTick: {
          length: -5,
          show: false,
          lineStyle: {
            color: "#000",
            width: 10,
          },
        },
        splitLine: {
          length: 10,
          show: false,
          offsetCenter: [0, "-60%"],
          lineStyle: {
            
            width: 1,
          },
          
        },
        title: {
          offsetCenter: [0, "40%"],
          fontSize:   h ? (h / 15).toFixed(0) : 0,
          fontFamily: "Roboto",
          fontWeight: 500,
          color: theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
        },

        detail: {

          fontSize:   h ? (h / 6).toFixed(0) : 0, 
          fontWeight: 500,

          fontFamily: "Roboto",

          offsetCenter: [0, "0%"],
          valueAnimation: true,
          formatter: function (value) {
            if (value > 99) {
              return `${value.toFixed(0)}`;
            }
            if (value < 1 && value > 0) {
              return `${parseFloat(value.toFixed(3)).toLocaleString("pt-BR", {
                minimumFractionDigits: 3,
              })}`;
            }
            if (!value || value < 0) {
              return `${"0"}`;
            }

            return `${parseFloat(value.toFixed(2)).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })}`;
          },
          color: theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
        },
        data: [
          {
            value: value,
            name: elemento.speedometer_main.unit.abbreviation,
            interval: range,
            color: theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
          },
        ],
      },
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 60,
        min: 0,
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)),

        itemStyle: {
          color: "#118751",
        },
        progress: {
          show: true,
          width: value === 0 ? 0 : 30,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: [[1, "transparent"]],
            shadowColor: "white",
            shadowBlur: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: value,
            color: theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
          },
        ],
      },
      {
        type: "gauge",
        startAngle: 60,
        endAngle: 0,
        min:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)),
        max:
          range &&
          parseFloat(range[1] < 1 ? range[1].toFixed(2) : range[1].toFixed(0)) *
            1.5,
        itemStyle: {
          color: "#c04040",
        },
        progress: {
          show: true,
          width: value === 0 ? 0 : 30,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: true,

          lineStyle: {
            width: -2,
            color: [[1, "transparent"]],
            shadowColor: "white",
            shadowBlur: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: value,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        opacity: started ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
       
        
        
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        listStyle: "none",
        p: "10px",
        m: "0px",
        width: w,
        height: h,
      }}
     
    >
      <Box
        className="draggableHandle"
        title={elemento.name}
        sx={{
         
         width: w,
         flexGrow: 1 ,  
         textOverflow: "ellipsis", 
         overflow: "hidden", 
         whiteSpace: "nowrap",
         zIndex: 2,
         cursor: 'grab',
        
        }}
      >
        {elemento.name}
      </Box>

      
        <ReactEcharts
          option={option}
          
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '-70px',
            marginTop: '0px',
            
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "end",
            paddingTop: "50px",
            paddingRight: "30px",
            position: "absolute",
            width: "100%",
            height: "100%",
            
          }}
        >
          {isDigitalTwinOn && status === "normal" && <Ok />}
          {isDigitalTwinOn && status === "alert" && <Alert />}
          {isDigitalTwinOn && status === "alarm" && <Alarm />}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
            margin: " 0 auto",
            width: w?.toString(),

            paddingBottom: "10px",
            // background:"red",
          }}
        >
          <ReferenceValue
            width={w}
            value={range ? range[1] : 0}
            sensorName={elemento.y_axis_1[0]?.name}
            fontSize="15px"
          ></ReferenceValue>

          {elemento.speedormeter_secondary.map((sensor) => (
            <span key={sensor}>
              {secondaryValues ? (
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Roboto",
                    
                    fontSize: "15px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: w?.toString(),
                    // animation: `marquee 5s linear infinite`,
                    textOverflow: "ellipsis",
                  }}
                >
                  <b>
                    {sensor.name[0].toUpperCase() + sensor.name.substring(1)}:
                  </b>{" "}
                  {secondaryValues[sensor.sensorId] ? "em uso" : "desligado"}
                </div>
              ) : (
                <span style={{}}>
                  <div
                    style={{
                      display: "inline-block",
                      fontFamily: "Roboto",
                      
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: w?.toString(),
                      textOverflow: "ellipsis",
                    }}
                  >
                    <b>
                      {sensor.name[0].toUpperCase() + sensor.name.substring(1)}:
                    </b>{" "}
                    <CircularProgress sx={{ color: "inherit" }} size={15} />
                  </div>
                </span>
              )}
            </span>
          ))}
        </div>
      
    </Paper>
  );
};

export default CardVelocimetro;
