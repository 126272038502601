
import "./UserCard.css";

import { Button, useTheme } from "@mui/material";

const UserCard = (props: any) => {
 
  let style = {};

  const theme = useTheme();
  if (props.user.is_active === false) {
    style = { backgroundColor: "#babab8", borderColor: "#80807e" };
  }
  return (
    <div className="userCard" style={style}>
      <div style={{ display: "flex", flexDirection: "row" ,gap:'10px'}}>
        {props.user.perfil && <img src={props.user.perfil} alt="Avatar" style={{ borderRadius: "50%", height: "100px", width: "100px" }} />}
        <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238",fontSize:'20px' }}>
          {props.user.name}
          {props.user.is_active === false && <span> (Inativo)</span>}
        <p>{props.user.email}</p>
        </p>

      </div>
      <Button sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} onClick={props.onClick}>Editar</Button>
      
    </div>
  );
};
export default UserCard;
