import { createSlice } from "@reduxjs/toolkit";

interface DB {
  dbName: string;
  collection: string;
}

const initialState: DB = {
  dbName: "",
  collection: "",
};

export const dbSlice = createSlice({
  name: "db",
  initialState,
  reducers: {
    setDb: (state, action) => {
      state.dbName = action.payload.dbName;
      state.collection = action.payload.collection;
    },
    resetDb: (state) => {
      state.dbName = "";
      state.collection = "";
    },
  },
});

export const getDb = (state: any) => state.db;

export const { setDb, resetDb } = dbSlice.actions;

export default dbSlice.reducer;

