import { Box, Paper, useTheme } from "@mui/material";
import moment, { Moment, now } from "moment";
import { useEffect, useRef, useState } from "react";
import "react-dates/lib/css/_datepicker.css";

import Charts from "../../Components/Analytics/Charts";
import Parameters from "../../Components/Analytics/Parameters";
import analyticsLineChart from "../../GraphOptions/AnalyticsLineChart";
import getGASTECHomologationNormal from "../../GraphOptions/GASTECHomologationNormal";
import GASTECMonitoringPie from "../../GraphOptions/GASTECMonitoringPie";
import DbService from "../../Services/LocalDataService";
import Di2winDataService from "../../Services/NewDi2winDataService";
import { hideLoading } from "../../Utils/hideLoading";
import { showLoading } from "../../Utils/showLoading";
import "./AnalyticsCompanyView.css";
import { getDb } from "../../store/slices/dbSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticsHistoricalData, setAnalyticsFrequencyData, setAnalyticsAlarmData , setAnalyticsChartsOn } from "../../store/slices/analyticsSlice";
import { getCompanyId } from "../../store/slices/companyslice";

interface Isensor {
  number: number;
  name: string;
  variable_type: string;
  unit: string;
  device_id: string;
}

const AnalyticsView = (props: any) => {
  const theme = useTheme();

  const db = useSelector(getDb);
  const companyID = useSelector(getCompanyId)
 

  const [data, setData] = useState<any>();
  const [digitalTwinIndex, setDigitalTwin] = useState<number | null>(null);

  const [selectedSensor, setSelectedSensor] = useState<Isensor | null>(null);

  const [empty, setEmpty] = useState<boolean>(true);

  const [startDate1, setStartDate1] = useState<Moment | null>(
    moment().subtract(7, "days")
  );
  const [endDate1, setEndDate1] = useState<Moment | null>(moment(now()));

  const [focusedInput, setFocusedInput] = useState();

  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);

  const historicalChartRef1 = useRef(null as any);
  const historicalChartRef2 = useRef(null as any);

  const alarmTimeChartRef1 = useRef(null as any);
  const alarmTimeChartRef2 = useRef(null as any);
  const alarmTimeChartRefTotal = useRef(null as any);

  const frequencyRangeChartRef1 = useRef(null as any);
  const frequencyRangeChartRef2 = useRef(null as any);

  const dispatch = useDispatch();


  useEffect(() => {
    
      DbService.get_sensors_from_company(companyID).then(
        (data) => {
          setData(data);
        }
      );
  
  }, [companyID]);

  const formatInputDate = (startDate, endDate, startTime, endTime) => {
    let startDate1ToSend: any = startDate
      ?.clone()
      .hour(parseInt(startTime.split(":")[0]));
    startDate1ToSend?.minute(parseInt(startTime.split(":")[1]));

    let endDate1toSend: any = endDate
      ?.clone()
      .hour(parseInt(endTime.split(":")[0]));
    endDate1toSend?.minute(parseInt(endTime.split(":")[1]));

    return [startDate1ToSend, endDate1toSend];
  };

  const renderChart = (chartRef, currentData, recommendedData, nChart) => {
    if (currentData !== null && recommendedData !== null) {
      let currentValues = currentData.val.map((value) => parseInt(value));
      let recommendedValues = recommendedData.val.map((value) =>
        parseInt(value)
      );

      let allValues = Array.from(
        new Set([...currentValues, ...recommendedValues])
      );
      allValues.sort((a, b) => a - b);

      let currentFreq = allValues.map((value) => {
        let index = currentValues.indexOf(value);
        return index !== -1 ? currentData.freq[index] : 0;
      });

      let recommendedFreq = allValues.map((value) => {
        let index = recommendedValues.indexOf(value);
        return index !== -1 ? recommendedData.freq[index] : 0;
      });

      chartRef.current
        ?.getEchartsInstance()
        .setOption(
          getGASTECHomologationNormal(
            currentFreq,
            recommendedFreq,
            allValues,
            "Valor lido",
            nChart === 1 ? "Valor recomendado" : "Valor otimizado",
            "Freq. do Valor Lido",
            nChart === 1
              ? "Freq. do Valor Recomendado"
              : "Freq. do Valor Otimizado"
          ),
          true
        );
    }
  };

  const renderHistoricalChart = (chartRef, currentData, recommendedData) => {
    if (currentData && recommendedData && selectedSensor) {
      // Process current data
      const currentChartData = {
        name: "Valor lido",
        number: selectedSensor.number,
        deviceId: selectedSensor.device_id,
        data: currentData.map((item) => [
          item.timestamp,
          item.value.toFixed(2).toString(),
        ]),
        unit: "Nm³/ton",
      };

      const recommendedChartData = {
        name: "Valor Recomendado",
        number: 1,
        deviceId: "1000",
        data: recommendedData.map((item) => [
          item.timestamp,
          item.value.toFixed(2).toString(),
        ]),
        unit: "Nm³/ton",
      };

      const chartData = [currentChartData, recommendedChartData];

      chartRef.current
        ?.getEchartsInstance()
        .setOption(
          analyticsLineChart({ yAxis1: chartData, theme: theme.palette.mode === "dark" ? true : false }),
        );
    }
  };

  const fetchAndRenderHistoricalData = async (range) => {
    if (digitalTwinIndex !== null && selectedSensor !== null) {
      try {
        const dataPromises = [
          Di2winDataService.getRawInfo( db.dbName,
            db.collection,6, "1000", range.start, range.end),
          Di2winDataService.getRawInfo(db.dbName,
            db.collection, 5, "1000", range.start, range.end),
          Di2winDataService.getRawInfo( db.dbName,
            db.collection, 8, "1000", range.start, range.end),
          Di2winDataService.getRawInfo( db.dbName,
            db.collection, 7, "1000", range.start, range.end),
        ];

        const dataResults = await Promise.all(dataPromises);
        dispatch(setAnalyticsHistoricalData(dataResults));

        renderHistoricalChart(
          historicalChartRef1,
          dataResults[0],
          dataResults[1]
        );
        renderHistoricalChart(
          historicalChartRef2,
          dataResults[2],
          dataResults[3]
        );

        hideLoading([historicalChartRef1, historicalChartRef2]);
        dispatch(setAnalyticsChartsOn(true));
      } catch (error) {
        console.error("Failed to fetch historical data", error);
      }
    }
  };

  const fetchAndRenderFrequencyData = async (range) => {
    if (digitalTwinIndex !== null && selectedSensor !== null) {
      try {
        const dataPromises = [
          Di2winDataService.fetchAnalyticsData("1000", 6, [range]),
          Di2winDataService.fetchAnalyticsData("1000", 5, [range]),
          Di2winDataService.fetchAnalyticsData("1000", 8, [range]),
          Di2winDataService.fetchAnalyticsData("1000", 7, [range]),
        ];

        const dataResults = await Promise.all(dataPromises);
        dispatch(setAnalyticsFrequencyData(dataResults));

        renderChart(
          frequencyRangeChartRef1,
          dataResults[0][0].data,
          dataResults[1][0].data,
          1
        );
        renderChart(
          frequencyRangeChartRef2,
          dataResults[2][0].data,
          dataResults[3][0].data,
          2
        );

        hideLoading([frequencyRangeChartRef1, frequencyRangeChartRef2]);
      } catch (error) {
        console.error("Failed to fetch frequency data", error);
      }
    }
  };

  const fetchAndRenderAlarmData = async (range, chartRef) => {
    
      try {
        const alarmData = digitalTwinIndex && await Di2winDataService.getAlarmData(
          data.digitalTwins[digitalTwinIndex].id,
          range[0],
          range[1]
        );
        dispatch(setAnalyticsAlarmData(alarmData));

        let timeInAlert = 0;
        alarmData.forEach((alert) => {
          let start = moment(alert.first_alert_time);
          let end = moment(alert.last_alert_time);

          if (start.isBefore(range[0])) {
            start = range[0];
          }
          if (end.isAfter(range[1])) {
            end = range[1];
          }

          if (start.isBefore(end)) {
            timeInAlert += end.diff(start);
          }
        });

        const totalTime = range[1].diff(range[0]);
        const timeOk = totalTime - timeInAlert;

        chartRef.current
          ?.getEchartsInstance()
          .setOption(
            GASTECMonitoringPie(
              "Sem Alarme",
              timeOk,
              "Em alarme",
              timeInAlert,
              totalTime,
              theme.palette.mode === "dark" ? true : false
            )
          );

        hideLoading([chartRef]);
      } catch (error) {
        console.error("Failed to render alarm data", error);
      }
    
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setAnalyticsChartsOn(false));
    if (digitalTwinIndex !== null && selectedSensor !== null) {
      setEmpty(false);

      setTimeout(async () => {
        showLoading([
          historicalChartRef1,
          historicalChartRef2,
          alarmTimeChartRef1,
          alarmTimeChartRef2,
          alarmTimeChartRefTotal,
          frequencyRangeChartRef1,
          frequencyRangeChartRef2,
        ]);

        let auxDates1 = formatInputDate(
          startDate1,
          endDate1,
          startTime,
          endTime
        );
        const range = { start: auxDates1[0], end: auxDates1[1] };

        fetchAndRenderFrequencyData(range);
        fetchAndRenderHistoricalData(range);
        fetchAndRenderAlarmData(auxDates1, alarmTimeChartRef1);
      }, 10);
    }
  };

 


  return (
    <Box
      
      sx={{ display: "grid", gridTemplateColumns: "2fr 7fr", padding: "10px", gap: "10px" ,height: "92vh"}}
    >
      
        <Parameters
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          startDate1={startDate1}
          setStartDate1={setStartDate1}
          endDate1={endDate1}
          setEndDate1={setEndDate1}
          setStartTime={setStartTime}
          startTime={startTime}
          setEndTime={setEndTime}
          endTime={endTime}
          selectedSensor={selectedSensor}
          setSelectedSensor={setSelectedSensor}
          digitalTwin={digitalTwinIndex}
          setDigitalTwin={setDigitalTwin}
          handleSubmit={handleSubmit}
          isDarkTheme={theme.palette.mode === "dark" ? true : false}
          formatInputDate={formatInputDate}
          data={data}
        />
      
      {!empty ? (
        <Charts
            isDarkTheme={theme.palette.mode === "dark" ? true : false}
            selectedSensor={selectedSensor}
            historicalChartRef1={historicalChartRef1}
            historicalChartRef2={historicalChartRef2}
            alarmTimeChartRef1={alarmTimeChartRef1}
            alarmTimeChartRef2={alarmTimeChartRef2}
            alarmTimeChartRefTotal={alarmTimeChartRefTotal}
            frequencyRangeChartRef1={frequencyRangeChartRef1}
            frequencyRangeChartRef2={frequencyRangeChartRef2}
          />
       
      ) : (
       
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
           
              Selecione os parâmetros
            
          </Paper>
        
      )}
    </Box>
  );
};
export default AnalyticsView;
