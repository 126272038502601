import { useEffect, useState } from "react";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";



import DbService from "../../Services/LocalDataService";

import { useParams } from "react-router-dom";
import CompanyCard from "../../Components/Cards/CompanyCard/CompanyCard";
import UserCard from "../../Components/Cards/UserCard/UserCard";
import DigitalTwinCard from "../../Components/Cards/DigitalTwinCard/DigitalTwinCard";
import NewDigitalTwinForm from "../../Components/Forms/NewDigitalTwinForm/NewDigitalTwinForm";
import NewUserForm from "../../Components/Forms/NewUserForm/NewUserForm";

// import logo from "../../Assets/Images/logo_asa.png";

import UserDetailsCard from "../../Components/Cards/UserDetailsCard/UserDetailsCard";
import DigitalTwinDetailsCard from "../../Components/Cards/DigitalTwinDetailsCard/DigitalTwinDetailsCard";

import {
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
  Button,
  Box,
  useTheme,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import AuthService from "../../Services/AuthService";
import { useDispatch, useSelector } from "react-redux";

import { getUser } from "../../store/slices/userSlice";

import { getCompanies, setCompaniess } from "../../store/slices/companiesSlice";

interface IGroup {
  id: number;
  name: string;
  cnpj: string;
  address: string;
  number: string;
  logo: string;
  neighborhood: string;
  zipcode: string;
  date: string;
  companies: Array<ICompany>;

}

interface ICompany {
  id: number;
  name: string;
  inclusion_date: string;
  cnpj: string;
  updatedAt: string;
  users: Array<IUsers>;
  digitalTwins: Array<IDigitalTwin>;
  complement: string;
  neighborhood: string;
  zipcode: string;
  address: string;
  number: string;

}

interface IUsers {
  id: number;
  name: string;
  email: string;
  password: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  email_confirmation_code: string;

}

interface IDigitalTwin {
  id: number;
  name: string;
  assets: any;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  parent_id: number;
}

const SetupGroup = (props: any) => {
  const { id } = useParams();

  const [addingCompany, setAddingCompany] = useState<boolean>(false);
  const [addingDigitalTwin, setAddingDigitalTwin] = useState<boolean>(false);
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [users, setUsers] = useState<IUsers[]>([]);
  const [usersList, setUsersList] = useState<IUsers[]>([]);
  const [digitalTwins, setDigitalTwins] = useState<IDigitalTwin[]>([]);
  const [digitalTwinsList, setDigitalTwinsList] = useState<IDigitalTwin[]>([]);
  // const [parentDts, setParentsDts] = useState<IDigitalTwin[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const [selected, setSelected] = useState<number | null>(null);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedDt, setSelectedDt] = useState<number | null>(null);

  const [isEditingName, setIsEditingName] = useState(false);
  const [newGroupName, setNewGroupName] = useState<string>(group?.name || '');


  
  const handleGroupNameChange = async () => {
    if (group && newGroupName !== group.name) {
      const updatedGroup = { ...group, name: newGroupName };
      const response = await DbService.editGroup(updatedGroup);
      setGroup(response);
      setIsEditingName(false);
    }
  };
  useEffect(() => {
   
    if (id) {
      DbService.get_group(id).then((data) => {
        // console.log(data);
        setGroup(data);
        
        setCompanies(data.companies);
      });
    }
  }, [id]);

  const togle = (company_id: number) => {
    setSelected(company_id);
    if (group !== null) {
    }

    DbService.get_users_from_company(company_id.toString()).then((data) => {
      setUsers(data);
      setUsersList(data);
    });
    DbService.get_digitalTwins(company_id.toString()).then((data) => {
      
      setDigitalTwins(data);
      const parents = data.filter((dt) => dt.parent_id === null);
     
      setDigitalTwinsList(parents);
    });
    
  };

  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "companySearch") {
      if (group) {
        const newComp = group.companies.filter((companie) => {
          if (companie.name.toLowerCase().includes(value.toLocaleLowerCase())) {
            return companie;
          } else {
            return undefined;
          }
        });
        setCompanies(newComp);
      }
    } else if (name === "userSearch") {
      const newUsers = users.filter((user) => {
        if (user.name.toLowerCase().includes(value.toLocaleLowerCase())) {
          return user;
        } else {
          return undefined;
        }
      });
      setUsersList(newUsers);
    } else if (name === "digitalTwinSearch") {
      const newDts = digitalTwins.filter((dt) => {
        if (dt.name.toLowerCase().includes(value.toLocaleLowerCase())) {
          return dt;
        } else {
          return undefined;
        }
      });
      setDigitalTwinsList(newDts);
    }
  };



  

  const handleNewUser = async () => {
    if (id && selected) {
      await DbService.get_users_from_company(selected.toString()).then(
        (data) => {
          setUsers(data);
          setUsersList(data);
        }
      );
    }
    setAddingUser(false);
    setSelectedUser(null);
    setSelected(null);
  };

  const handleNewDt = () => {
    if (id && selected) {
      DbService.get_digitalTwins(selected.toString()).then((data) => {
        setDigitalTwins(data);
        const parents = data.filter((dt) => dt.parent_id === null);
        // setParentsDts(parents);
        setDigitalTwinsList(parents);
      });
    }
    setAddingDigitalTwin(false);
    setSelectedDt(null);
  };
  // setSelectedUser(1);

  

  const activateUser = ( ) => {
    
    if (selectedUser) {
      const user = users.filter((user) => user.id === selectedUser)[0];
     
      AuthService.activationCode(user?.email_confirmation_code)
      setSelectedUser(null);
      
    }
  }

  const [anchorLogo, setAnchorLogo] = useState<null | HTMLElement>(null);
  const [openLogo, setOpenLogo] = useState(false);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState<any>(null);
  const [selectedLogo, setSelectedLogo] = useState<any>(null);

  const companiess = useSelector(getCompanies);
  const user = useSelector(getUser);
  const handleOpenLogoMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLogo(event.currentTarget as HTMLElement);
    setOpenLogo(true);
  }

  const handleLogoChange = async(e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  const handleCloseLogoMenu = () => {
    setAnchorLogo(null);
    setOpenLogo(false);
  }
  const handleCancelLogo = () => {
    setLogoPreviewUrl(user.logo ? user.logo : null);
    setSelectedLogo(null);
    handleCloseLogoMenu();
  };

  const dispatch = useDispatch();
  const handleSaveLogo = async () => {
    // group && console.log(logoPreviewUrl);
    const groupModified = {...group, logo: logoPreviewUrl};
    // console.log(groupModified);
    const updatedGroup = await DbService.editGroup(groupModified)

    setGroup(updatedGroup);
    
    setSelectedLogo(null);
    handleCloseLogoMenu();
  
    // Atualiza o complement da empresa selecionada
    const updatedCompanies = companiess.map(company => {
      if (company.id === group?.id) { // Supondo que group?.id seja o id da empresa selecionada
        return {
          ...group,
          complement: selectedLogo, // Substitua selectedLogo pelo valor desejado
        };
      }
      return company; // Retorna a empresa sem alterações se não for a selecionada
    });
    // console.log(updatedCompanies);
    // Dispara a ação para atualizar o estado das empresas
    dispatch(setCompaniess(updatedCompanies));
  
    return; // Garante que a função retorne algo (nesse caso, undefined)
  };

  const handleAddCompany = async() => {
    try {
      const newCompany = await DbService.createCompany({
        groupId: id,
        newCompanyName,
        cnpj,
        complement,
      });
      
      setCompanies([...companies, newCompany]);
      setAddingCompany(false);
     
      setNewCompanyName(null);
      setCnpj(null);
      setComplement(null);
    } catch (error) {
      alert(error);
    }
    
  }

  const [complement, setComplement] = useState<any>(null);
  const [newCompanyName, setNewCompanyName] = useState<any>(null);
  const [cnpj, setCnpj] = useState<any>(null);
 

  const handleComplementChange = async(e) => {
    const file = e.target.files[0];
    setComplement(file);
    const reader = new FileReader() ;
    reader.onloadend = () => {
      setComplement(reader.result as string );
    };
    reader.readAsDataURL(file );
  }

  

  const handleDeleteCompany = async (id) => {
    
    try {
      if (id !== null) {
        await DbService.deleteCompany(id);
        setCompanies(companies.filter((company) => company.id !== id));
    
        
      }
    } catch (error) {
      alert(error);
    }
  };




  const theme = useTheme();
  return (
    <Box sx={{ display: "grid",gridTemplateColumns: "1fr 1fr 1fr", flexDirection: "row", gap: "10px",margin:'10px', width: "calc(100% - 70px)",height: "calc(100% - 10px)" }} >
      <Menu
       anchorEl={anchorLogo}
            open={openLogo}
            onClose={()=>setOpenLogo(false)}
           sx={{
          
            width: "330px",
           }}
          >
            
            <input
        type="file"
        style={{ display: 'block' }}
        onChange={(event) => handleLogoChange(event)}
      />
       {/* <Button  sx={{width: "100%"}} onClick={handleUploadClick}>{imagePreviewUrl ? (
        <div>
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) : "Selecionar imagem"}</Button> */}

{logoPreviewUrl && (
        <div>
          <img
            src={logoPreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) }

       
            {selectedLogo && <MenuItem  sx={{width: "100%"}} onClick={() => handleSaveLogo()}>Salvar</MenuItem>}
            {selectedLogo &&  <MenuItem  sx={{width: "100%"}} onClick={() => handleCancelLogo()}>Cancelar</MenuItem>}

            
          </Menu>
      <Paper
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "calc(100% - 120px)",
        padding: "10px",
        justifyContent: "space-between",
      }}
      >

        <div className="groupDiv">
        <Typography sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} className="sectionTittle">Organização</Typography>
          {group && (
            <Box sx={{ display: "flex", gap: "10px" }}>
            <img
            style={{ maxWidth: "300px" }}
              src={group.logo || 'https://www.horizon-fm.fr/wp-content/uploads/2020/05/cropped-LogoVDE-1.png'}
              
              alt="Logo"
              onClick={(event) =>handleOpenLogoMenu(event)}
            />
            </Box>
          )}
          

          <Typography 
  variant="h5" 
  sx={{ 
    margin: "10px auto", 
    color: theme.palette.mode === "dark" ? "white" : "#323238", 
    textAlign: "center" 
  }}
  onDoubleClick={() => setIsEditingName(true)}
>
  {isEditingName ? (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px",justifyContent: "center" }}>
      <OutlinedInput
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
        autoFocus
        placeholder={group?.name}
        sx={{ marginRight: "10px" }}
      />
      <button
        onClick={handleGroupNameChange}
        style={{
          backgroundColor: "#124251",
          color: "#FFF",
          margin: "10px",
          padding: "10px",
          borderRadius: "5px",
          fontSize: "14px",
        }}
      >
        Confirmar
      </button>
      <button
        onClick={() => setIsEditingName(false)}
        style={{
          backgroundColor: "red",
          color: "#FFF",
          margin: "10px",
          padding: "10px",
          borderRadius: "5px",
          fontSize: "14px",
        }}
        
      >
        Cancelar
      </button>
    </Box>
  ) : (
    group?.name
  )}
</Typography>

  
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} className="sectionTittle">Empresas</Typography> 
          <button
              onClick={() => {
                setAddingCompany(true);
              }}
              style={{
                backgroundColor: "#124251",
                color: "#FFF",
                margin: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
              
            >
              Adicionar
            </button>
            <Dialog open={addingCompany} onClose={() => setAddingCompany(false)} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <DialogTitle sx={{ textAlign: "center",backgroundColor: "#124251", color: "white" }}>Adicionar Empresa</DialogTitle>

        <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop: "10px",flexDirection: "column",gap: "10px"}}>
          <input
            type="file"
            style={{ display: 'block' }}
            onChange={handleComplementChange}
            accept="image/png, image/jpeg"
            

          />
            {complement && <img src={complement} alt="Logo da empresa" style={{ maxWidth: "300px" }} />}
          <TextField
            
            
            label="Nome da Empresa"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
          <TextField
            
            
            label="Cnpj"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />



          
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={() => setAddingCompany(false)}>Cancelar</Button>
          <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => handleAddCompany()}>Adicionar</Button>
        </DialogActions>
      </Dialog>
            </Box>

            

          {companies.map((company) => {
                    return (
            
                      <CompanyCard
                        key={company.id}
                        selected={company.id === selected ? true : false}
                        onDelete={ () => handleDeleteCompany(company.id)}
                        onSelect={() => togle(company.id)}
                        company={company}
                        
                      />
                    );
                  })
                }
            
          
          </div>

      </Paper>

      <Paper
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "calc(100% - 120px)",
          padding: "10px",
          // justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column",height: "calc(100% - 45px" }}>
          <Typography sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} className="sectionTittle">Usuarios</Typography>
          {!selected ? (
            <span></span>
          ) : addingUser ? (
            <div>
              <NewUserForm
                companyId={selected}
                handleResponse={handleNewUser}
                cancel={() => {
                  setAddingUser(false);
                }}
              />
            </div>
          ) : selectedUser ? (
            <UserDetailsCard
              selected={selected}

              handleResponse={handleNewUser}
              user={usersList.find((user) => user.id === selectedUser)}
              cancel={() => {
                setAddingUser(false);
              }}
              activateUser={() => {
                activateUser();
              }}
            />
          ) : (
            <div className="list" style={{ display: "flex", flexDirection: "column",overflow: "auto",height: "calc(100% - 70px" }}>
              <OutlinedInput
                sx={{ width: "97%", margin: "10px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <Icon />
                  </InputAdornment>
                }
                type="text"
                name="userSearch"
                onChange={searchInputHandler}
              />

              {usersList.map((user) => {
                return (
                  <UserCard
                    user={user}
                    onClick={() => {
                      setSelectedUser(user.id);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {selected && !addingUser && (
            // <button
            //   className="bottomButton"
            //   onClick={() => {
            //     setAddingUser(!addingUser);
            //   }}
            // >
            //   Adicionar usuario
            // </button>
            <Button
              onClick={() => {
                setAddingUser(!addingUser);
              }}
              sx={{
                backgroundColor: "#124251",
                color: "#FFF",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Adicionar usuario
            </Button>
          )}
        </div>
      </Paper>

      <Paper
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "calc(100% - 120px)",
          padding: "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div>
            <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} className="sectionTittle">Gêmeos Digitais</p>
          </div>
          <div className="">
            {!selected ? (
              <span></span>
            ) : addingDigitalTwin ? (
              <div>
                <NewDigitalTwinForm
                  companyId={selected}
                  dts={digitalTwins}
                  handleResponse={handleNewDt}
                  cancel={() => {
                    setAddingDigitalTwin(false);
                  }}
                />
              </div>
            ) : selectedDt ? (
              <DigitalTwinDetailsCard
                handleResponse={handleNewDt}
                dts={digitalTwinsList.filter((dt) => dt.id !== selectedDt)}
                dt={digitalTwins.find((dt) => dt.id === selectedDt)}
              />
            ) : (
              <div className="list">
                <div className="searchDiv">
                  <input
                    type="text"
                    name="digitalTwinSearch"
                    id=""
                    onChange={searchInputHandler}
                  />
                  <Icon />
                </div>
                <div>
                  {digitalTwinsList.map((dt) => {
                    return (
                      <DigitalTwinCard
                        dt={dt}
                        onClick={(id) => {
                          setSelectedDt(id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {/* {!addingDigitalTwin ? (
                selected === 0 ? (
                  <p>waiting to select</p>
                ) : (
                  <div className="list">
                    <div className="searchDiv">
                      <input
                        type="text"
                        name="digitalTwinSearch"
                        id=""
                        onChange={searchInputHandler}
                      />
                      <Icon />
                    </div>
                    <div>
                      {digitalTwinsList.map((dt) => {
                        return (
                          <DigitalTwinCard
                            dt={dt}
                            onClick={() => {
                              setSelectedDt(dt.id);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <NewDigitalTwinForm
                    companyId={selected}
                    dts={digitalTwins}
                    handleResponse={handleNewDt}
                  />
                </div>
              )}*/}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {selected && !addingDigitalTwin && (
            // <button
            //   className="bottomButton"
            //   onClick={() => {
            //     setAddingDigitalTwin(!addingDigitalTwin);
            //   }}
            // >
            //   Adicionar gemeo..
            // </button>
            <Button
              onClick={() => {
                setAddingDigitalTwin(!addingDigitalTwin);
              }}
              style={{
                backgroundColor: "#124251",
                color: "#FFF",
                marginRight: "10px",
                alignSelf: "flex-end",
              }}
              variant="contained"
            >
              Adicionar Gêmeo digital
            </Button>
          )}
        </div>
      </Paper>
    </Box>
  );
};
export default SetupGroup;
