import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from "../../../Assets/Icons/edit.svg";

import "./DigitalTwinCard.css";
import { useTheme } from "@mui/material";

const DigitalTwinCard = (props: any) => {
  const navigate = useNavigate();
 const theme = useTheme();
  return (
    <div className="digitalTwinCard">
      <div
        className="parent"
        onClick={() => {
          navigate(`/digitaltwin/${props.dt.id}`);
        }}
      >
        <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>{props.dt.name}</p>
        <div className="icon">
          <Icon
            onClick={(event) => {
              event.stopPropagation();
              props.onClick(props.dt.id);
            }}
          />
        </div>
      </div>

      <ul>
        {props.dt.children.length !== 0 &&
          props.dt.children.map((child: any) => {
            return (
              <div
                key={child.name}
                className="childDiv"
                onClick={() => {
                  navigate(`/digitaltwin/${child.id}`);
                }}
              >
                <li>{child.name}</li>
                <div className="icon">
                  <Icon
                    onClick={(event) => {
                      event.stopPropagation();
                      props.onClick(child.id);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </ul>
    </div>
  );
};
export default DigitalTwinCard;
