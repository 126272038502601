import { Grid, Paper } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import Gain from "./Gain";
import { useMemo, useRef , useState} from "react";
import { Fullscreen } from "@mui/icons-material";

interface ChartProps {
  isDarkTheme: boolean;
  selectedSensor: any;
  historicalChartRef1: any;
  historicalChartRef2: any;
  alarmTimeChartRef1: any;
  alarmTimeChartRef2: any;
  alarmTimeChartRefTotal: any;
  frequencyRangeChartRef1: any;
  frequencyRangeChartRef2: any;
}

const Charts = (props: ChartProps) => {
  const isKpi = props.selectedSensor?.variable_type === "kpi";



  const [isFullscreen, setIsFullscreen] = useState(false);

  const chartContainerRef1 = useRef<HTMLDivElement>(null);
  const chartContainerRef2 = useRef<HTMLDivElement>(null);
  const chartContainerRef3 = useRef<HTMLDivElement>(null);
  const chartContainerRef4 = useRef<HTMLDivElement>(null);
  

  // };
  const toggleFullscreen = (chartContainerRef) => {
    if (chartContainerRef.current) {
      if (!isFullscreen) {
         
        chartContainerRef.current && chartContainerRef.current.requestFullscreen().catch(err => {
          console.error(`Failed to enable fullscreen mode: ${err.message} (${err.name})`);
        })
      } else {
        document.exitFullscreen().catch(err => {
          console.error(`Failed to exit fullscreen mode: ${err.message} (${err.name})`);
        });
      }
      setIsFullscreen(!isFullscreen);
    }
  };

const options = useMemo(() => ({
  
  backgroundColor: "transparent",
  toolbox: {
    top: 0,
     right: 15,
     show: true,
     feature: {
       saveAsImage: {
         title: "Baixar .png",
        
         pixelRatio: 4,
       },
       dataZoom: {
         title: {
           zoom: "Zoom",
           back: "Voltar",
         },
         yAxisIndex: "all",
         brushStyle: {
           borderWidth: 1,
           color: "rgba(255, 173, 17, 0.4)",
           borderColor: "rgba(255, 173, 17, 1)",
         },
       },
       magicType: {
         title: {
           line: "Linha",
           bar: "Barra",
         },
         type: ["line", "bar"],
       },
     },
   },
  grid: {
    top: "60px",
    left: "10px",
    right: "10px",
    bottom: "30px",
  }

}), []);

const styles = useMemo(() => ({
  top: "-35px",
  height: "100%",
  margin: "10px",

} ), []);

  return (
    <Grid
      container
      spacing={1}
      sx={{ width: "95%", height: "100%", padding: "10px 0" }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "24vh",
            margin: "0px",
          }}
          ref={chartContainerRef1} 
          onDoubleClick={()=>toggleFullscreen(chartContainerRef1)}  
        >
                
          <div style={{ display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              }}> 
         <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
              
            }}
          >
            {props.selectedSensor?.name} em Alarme
          </span>
            <Fullscreen style={{ cursor: "pointer", color: props.isDarkTheme ? "#fff" : "#124251" , zIndex: 1,paddingTop: "1px" }} onClick={()=>toggleFullscreen(chartContainerRef1)} />

</div>
        
          {/* <div title="Fullscreen" style={{ position: "absolute", top: "12px", right: "10px", color: theme.palette.mode === "dark" ? "#fff" : "#000",cursor: "pointer", zIndex: 1 }}>
          </div> */}

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.historicalChartRef1}
            style={styles}
            option={{...options, grid: {...options.grid, top: "60px"}}}
          />
          

          
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          className="a"
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "24vh",
            margin: "0px",
          }}
          ref={chartContainerRef2} 
          onDoubleClick={()=>toggleFullscreen(chartContainerRef2)}  
        >
          <div style={{ display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}> 
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "400",
                color: props.isDarkTheme ? "#fff" : "#124251",
              }}
            >
              {props.selectedSensor?.name} Total
            </span>
         
            <Fullscreen style={{ cursor: "pointer", color: props.isDarkTheme ? "#fff" : "#124251" , zIndex: 1,paddingTop: "1px" }} onClick={()=>toggleFullscreen(chartContainerRef2)} />

</div>

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.historicalChartRef2}
            style={styles}
            option={{...options, grid: {...options.grid, top: "60px"}}}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "24vh",
          }}
          ref={chartContainerRef3} 
          onDoubleClick={()=>toggleFullscreen(chartContainerRef3)}  
        >
          <div style={{ display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}> 
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "400",
                color: props.isDarkTheme ? "#fff" : "#124251",
              }}
            >
              Frequência em Alarme
            </span>
         
            <Fullscreen style={{ cursor: "pointer", color: props.isDarkTheme ? "#fff" : "#124251" , zIndex: 1,paddingTop: "1px" }} onClick={()=>toggleFullscreen(chartContainerRef3)} />

</div>

          <Gain
            chartRef={props.historicalChartRef1}
            isDarkTheme={props.isDarkTheme}
            isGain={false}
          />

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.frequencyRangeChartRef1}
            style={{right: "20px", ...styles}}
            option={{...options, grid: {...options.grid, top: "60px"}}}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "24vh",
          }}
          ref={chartContainerRef4} 
          onDoubleClick={()=>toggleFullscreen(chartContainerRef4)}  
        >
          <div style={{ display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}> 
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "400",
                color: props.isDarkTheme ? "#fff" : "#124251",
              }}
            >
              Frequência Total
            </span>
        
            <Fullscreen style={{ cursor: "pointer", color: props.isDarkTheme ? "#fff" : "#124251" , zIndex: 1,paddingTop: "1px" }} onClick={()=>toggleFullscreen(chartContainerRef4)} />

</div>

          <Gain
            chartRef={props.historicalChartRef2}
            isDarkTheme={props.isDarkTheme}
            isGain={false}
          />

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.frequencyRangeChartRef2}
            style={{right: "20px", ...styles}}
            option={{...options, grid: {...options.grid, top: "60px"}}}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
            height: "31vh",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Tempo em Alarme" : "Prescrições no Período"}
          </span>

          <ReactEcharts
            theme={props.isDarkTheme ? "dark" : "light"}
            ref={props.alarmTimeChartRef1}
            style={styles}
            option={options}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            height: "31vh",
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Ganho em Alarme" : null}
          </span>
          <Gain
            chartRef={props.historicalChartRef1}
            isDarkTheme={props.isDarkTheme}
            isAlarm
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          className="b"
          sx={{
            height: "31vh",
            backgroundColor: props.isDarkTheme ? "#323238" : "#fdfdff",
            padding: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: props.isDarkTheme ? "#fff" : "#124251",
            }}
          >
            {isKpi ? "Ganho Total" : null}
          </span>
          <Gain
            chartRef={props.historicalChartRef2}
            isDarkTheme={props.isDarkTheme}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Charts;