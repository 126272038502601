import { Paper, useTheme } from "@mui/material";
import { DraggableHandle } from "../../styles";
import RealTimeMultiSensorChart from "./RealTimeMultiSensorChart";

interface CardGraficoProps {
  chart: any;
  width: number | undefined;
  height: number | undefined;
  refIndx: number;
}

const CardGrafico = ({ chart, width, height, refIndx }: CardGraficoProps) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        // border: kpiSensors.length > 0 && status === "alarm" ? "3px solid red" : "none",
        transition: "opacity 0.5s ease-in-out",
        backgroundColor: theme.palette.mode === "dark" ? "#121212" : "white",
        color: theme.palette.mode === "dark" ? "white" : "#121212",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexWrap: "wrap",
        p: "0px 0px 0px 0px",
        m: "0px 0px 0px 0px",

        height: height,
        width: width,
      }}
    >
      <DraggableHandle className="draggableHandle" />

      <RealTimeMultiSensorChart
        sensorsConfig={chart.y_axis_1}
        time={chart.time}
        timeRange={chart.time_range}
        width={width}
        height={height}
        chartname={chart.name}
        chart={chart}
        refIndx={refIndx}
      />
    </Paper>
  );
};

export default CardGrafico;
