import axios from "axios";
import moment from "moment";

const Di2winDataService = {

  fetchMonitoringHistoricData: async function (
    sensorId: number,
    deviceId: string,
    timeToFetch: number,
    timeUnit: any
  ) {
    const formatDateTime = (momentObj) =>
      momentObj.format("YYYY-MM-DDTHH:mm:00.0-0300");

    const currentDate = moment().add(3, "hours");
    const pastDate = moment().add(3, "hours").subtract(timeToFetch, timeUnit);

    try {
      // Se o sensorId for 862, cria uma série de entradas para o sensor 666
      if (sensorId === 666 && deviceId === "b86de0652035eaac") {
        const entries = this.createSensor666Series();
        // console.log(entries);
        return entries; // Assuming you want to return these entries
      } else {
        const axiosResponse = await axios.get(
          `/newApi/get_raw_info?dbName=asa_data_prod&collectionName=asa_data_prod_stream&deviceId=${deviceId}&dayofweek=1&sensorId=${sensorId}&start_datetime=${formatDateTime(
            pastDate
          )}&end_datetime=${formatDateTime(currentDate)}`
        );

        // console.log(axiosResponse.data);
        return axiosResponse.data;
      }
    } catch (e) {
      throw e; // The error is thrown and will cause the returned Promise to be rejected
    }
  },

  getLastNInfo: async function (
    dbName: string,
    collectionName: string,
    sensorId: number,
    deviceId: string,
    timeToFetch: any,
    timeUnit: any,
    limit: string
  ) {
    const formatDateTime = (momentObj) =>
      momentObj.format("YYYY-MM-DDTHH:mm:00.0-0300");

    const pastDate = moment().subtract(timeToFetch, timeUnit);
    const currentDate = moment().add(3, "hours");

    const axiosResponse = await axios.get(`/newApi/getLastNInfo?dbName=${dbName}&collectionName=${collectionName}`, {
      params: {
        deviceId: deviceId,
        sensorId: sensorId,
        start_datetime: formatDateTime(pastDate),
        end_datetime: formatDateTime(currentDate),
        limit: limit,
      },
    });

    return axiosResponse.data;
  },

  getLastNInfoInRange: async function (
    dbName: string,
    collectionName: string,
    sensorId: number,
    deviceId: string,
    start_datetime: any,
    end_datetime: any,
    limit: string
  ) {
    const formatDateTime = (momentObj) =>
      momentObj.format("YYYY-MM-DDTHH:mm:00.0-0300");

    const axiosResponse = await axios.get(`/newApi/getLastNInfo?dbName=${dbName}&collectionName=${collectionName}`, {
      params: {
        deviceId: deviceId,
        sensorId: sensorId,
        start_datetime: formatDateTime(start_datetime),
        end_datetime: formatDateTime(end_datetime),
        limit: limit,
      },
    });

    return axiosResponse.data;
  },

  getRawInfo: async function (
    dbName: string,
    collectionName: string,
    sensorId: number,
    deviceId: string,
    start_datetime: any,
    end_datetime: any
  ) {
    const startDatetime = start_datetime
      .clone()
      .format("YYYY-MM-DDTHH:mm:ss.SZ");
    const endDatetime = end_datetime.clone().format("YYYY-MM-DDTHH:mm:ss.SZ");

    const axiosResponse = await axios.get(
      `/newApi/get_raw_info?dbName=${dbName}&collectionName=${collectionName}&deviceId=${deviceId}&sensorId=${sensorId}&start_datetime=${startDatetime}&end_datetime=${endDatetime}`
    );

    const formated =
      axiosResponse.data &&
      axiosResponse.data.map((element) => {
        if (typeof element.value === "string") {
          element.value = parseFloat(element.value);
        }
        return element;
      });

    return formated;
  },

  getRawInfoForAlert: async function (
    dbName: string,
    collectionName: string,
    sensorId: number,
    deviceId: string,
    firstAlertTime: moment.MomentInput,
    lastAlertTime: moment.MomentInput
  ) {
    const formatDateToAPI = (date: moment.MomentInput) => {
      return moment(date).add(3, "hours").format("YYYY-MM-DDTHH:mm:00.0-0300");
    };

    const start_datetime = formatDateToAPI(firstAlertTime);
    const end_datetime = formatDateToAPI(lastAlertTime);

    try {
      const axiosResponse = await axios.get(
        `/newApi/get_raw_info?dbName=${dbName}&collectionName=${collectionName}&deviceId=${deviceId}&sensorId=${sensorId}&start_datetime=${start_datetime}&end_datetime=${end_datetime}`
      );
      return axiosResponse.data;
    } catch (err) {
      console.error(err);
    }
  },

  createSensor666Series: function () {
    const currentHour = moment().hour();
    const entries: any = [];

    for (let hour = 0; hour <= currentHour; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const timestamp = moment()
          .startOf("day")
          .add(hour, "hours")
          .add(minute, "minutes");
        const entry = {
          sensorId: 666,
          value: 0,
          timestamp: timestamp.format("YYYY-MM-DDTHH:mm:00.0-0300"),
          deviceId: "b86de0652035eaac",
        };
        entries.push(entry);
      }
    }

    return entries;
  },

  fetchAnalyticsData: async function (deviceId, sensorId, ranges) {
    return Promise.all(
      ranges.map(async (range) => {
        try {
          const response = await axios.get(`/analytics`, {
            params: {
              deviceId: deviceId,
              sensorId: sensorId,
              startDate: range.start.format("YYYY-MM-DDTHH:mm:ss"),
              endDate: range.end.format("YYYY-MM-DDTHH:mm:ss"),
            },
          });
          return { ...range, data: response.data };
        } catch (error) {
          console.error("Error fetching analytics data", error);
          throw error;
        }
      })
    );
  },

  getAlarmData: async function (digitalTwin, start, end) {
    try {
      const response = await axios.get(`/alerts`, {
        params: {
          digital_twin_id: digitalTwin,
          gt: start.toJSON(),
          lt: end.toJSON(),
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch alarm data for twin", digitalTwin, error);
      throw error;
    }
  },
};

export default Di2winDataService;
