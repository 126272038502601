import LoginView from "../Views/LoginView/LoginView";
import SingUpView from "../Views/SignUpView/SignUpView";
import AnalyticsCompanyView from "../Views/AnalyticsCompanyView/AnalyticsCompanyView";
import AnalyticsHomeView from "../Views/AnalyticsHomeView/AnalyticsHomeView";
import DigitalTwinView from "../Views/DigitalTwinView/DigitalTwinView";
import FaqView from "../Views/FaqView/FaqView";
import SetupGroup from "../Views/SetupGroup/SetupGroup";
import SetupView from "../Views/SetupView/SetupView";
import HistoricDetailsView from "../Views/HistoricDetailsView/HistoricDetailsView";
import MonitoringViewNoHC from "../Views/MonitoringView/MonitoringView(NoHC)";
import RecommendationHistoryView from "../Views/RecommendationHistoryView/RecommendationHistoryView";
import PainelTwins from "../Views/PainelTwins/PainelTwins";
import ActivationView from "../Views/ActivationView/ActivationView";
import ResetPasswordView from "../Views/ResetPasswordView/ResetPasswordView";
import ChoseCompanyView from "../Views/ChoseCompanyView/ChooseCompanyView";
import paths from "./paths";
import WrapWithCardAlertas from "../Components/dinamicos/WrapWithCardAlertas";
import { CSSProperties } from "react";
import { Navigate } from "react-router-dom";

export interface Route {
  path: string;
  component: JSX.Element;
  style?: CSSProperties;

  requiresAuth?: boolean;
  requiresAdmin?: boolean;
  requiresCompanyId?: boolean;
  requiresDigitalTwinId?: boolean;

  extraConditions?: boolean[]; //extra conditions for rendering that route
  redirectRoutes?: string[]; //route to redirect in case of fail of same index condition
}





const useRoutes = (): Route[] => {
  return [
    {
      path: "*",
      component: <Navigate to={paths.PANEL_TWINS} />,
    },
    {
      path: paths.AUTH,
      component: <ActivationView></ActivationView>,
      
    },
    {
      path: paths.LOGIN_SCREEN,
      component: <LoginView></LoginView>,
     
    },
    {
      path: paths.SIGNUP,
      component: <SingUpView></SingUpView>,
      
    },
    {
      path: `${paths.RESET_PASSWORD}/:resetToken`,
      component: <ResetPasswordView></ResetPasswordView>,
      
    },
    {
      path: paths.CHOOSE_COMPANY,
      component: <ChoseCompanyView></ChoseCompanyView>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
    {
      path: paths.PANEL_TWINS,
      component: <PainelTwins></PainelTwins>,
      
      requiresAuth: true,
      requiresCompanyId: true,
    },
    {
      path: paths.PANEL_MONITORING,
      component: <WrapWithCardAlertas component={<MonitoringViewNoHC/>} />,
      
      requiresAuth: true,
      requiresCompanyId: true,
      requiresDigitalTwinId: true,
    },
    {
      path: paths.HISTORICO_RECOMENDACOES,
      component: (
        <WrapWithCardAlertas component={<RecommendationHistoryView />} />
      ),
      
      requiresAuth: true,
      requiresCompanyId: true,
      requiresDigitalTwinId: true,
    },
    {
      path: paths.HISTORICO_DADOS,
      component: <WrapWithCardAlertas component={<HistoricDetailsView />} />,
      
      requiresAuth: true,
      requiresCompanyId: true,
      requiresDigitalTwinId: true,
    },
    {
      path: paths.ANALYTICS_HOME_PAGE,
      component: <AnalyticsHomeView></AnalyticsHomeView>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
    {
      path: paths.ANALYTICS_COMPANY_PAGE,
      component: <AnalyticsCompanyView></AnalyticsCompanyView>,
     
      requiresAuth: true,
    },
    {
      path: paths.SETUP_GROUP,
      component: <SetupGroup></SetupGroup>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
    {
      path: paths.DIGITAL_TWIN_PAGE,
      component: <DigitalTwinView></DigitalTwinView>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
    {
      path: paths.FAQ,
      component: <FaqView></FaqView>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
    {
      path: paths.SETUP,
      component: <SetupView></SetupView>,
      
      requiresAuth: true,
      requiresAdmin: true,
    },
  ];
};

export default useRoutes;
