import {Card, CardMedia, useTheme } from "@mui/material";




const AddGroupCard =({ onClick }: { onClick: () => void }) => {
  const theme = useTheme()

  

  return (
    
      
     
      
    
    
    <Card
        onClick={onClick}
        sx={{
          cursor: "pointer",
          height: "273px",
          width: "273px",
          display: "flex-column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          
          backgroundColor: theme.palette.mode === "dark" ? "#323238" : "white",
          color: theme.palette.mode === "dark" ? "white" : "#323238" ,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "273px",
            height: "213px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <div style={{ width: "200px", margin: "auto" }}>
            <CardMedia
              component="img"
              image={'https://cdn-icons-png.flaticon.com/512/17216/17216594.png'}
              alt="add group"
              sx={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: "60px",
            justifyContent: "space-around",
            padding: "0px",
            alignItems: "center",
          }}
        >
          {'Novo Grupo'}
        </div>
      </Card>
    
  );
};
export default AddGroupCard;