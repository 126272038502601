import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputAdornment, OutlinedInput, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme } from '@mui/material';
import DbService from "../../Services/LocalDataService";
import GroupCard from "../../Components/Cards/GroupCard/GroupCard";
import AddGroupCard from "../../Components/Cards/AddGroupCard/AddGroupCard";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";


interface IGroup {
  id: number;
  name: string;
  logo: string;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
}

const SetupView = (props: any) => {
  const theme = useTheme();
  const [listedGroups, setListedGroups] = useState<IGroup[]>([]);
  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupLogo, setNewGroupLogo] = useState<any>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredSensors = listedGroups.filter((sensor) => {
      if (sensor.name.toLowerCase().includes(value.toLowerCase())) {
        return sensor;
      } else {
        return null;
      }
    });
    setListedGroups(filteredSensors);
  };

  useEffect(() => {
    // carregando os grupos
    DbService.get_groups().then((data) => {
      // console.log(data);
      setListedGroups(data);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddGroup =async () => {
    // lógica para adicionar o grupo
    const group = {
      
      name: newGroupName,
      logo: newGroupLogo,
      
    };
    const groupCreated = await DbService.createGroup(group);
    setListedGroups([...listedGroups, groupCreated]);
    handleClose();
  };

  const handleLogoChange = async(e) => {
    const file = e.target.files[0];
    setNewGroupLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewGroupLogo(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  const deleteGroup = async (id) => {
    await DbService.deleteGroup(id);
    setListedGroups(listedGroups.filter((g) => g.id !== id))
  }

  return (
    <Box sx={{ height: "calc(100vh - 80px)", width: "calc(100vw - 70px)", marginTop: "10px" }}>
      <FormControl id="sensorsSearch" variant="outlined">
        <OutlinedInput
          id="outlined-adornment-weight"
          placeholder="Pesquisar grupos"
          aria-label="weight"
          type="text"
          size="small"
          sx={{ width: "100%", height: "50px", margin: "5px" }}
          startAdornment={
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          onChange={handleSearch}
        />
      </FormControl>

      <Box sx={{ display: "flex",gap: "10px", flexWrap: "wrap", margin: "5px" }}>
        {listedGroups.map((group) => (
          
            <GroupCard
              key={group.id}
              group={group}
              onDelete={() => deleteGroup(group.id)}
            />
          
        ))}
        <AddGroupCard onClick={handleClickOpen} />
      </Box>

      <Dialog open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <DialogTitle sx={{ textAlign: "center",backgroundColor: "#124251", color: "white" }}>Adicionar Novo Grupo</DialogTitle>

        <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop: "10px",flexDirection: "column",gap: "10px"}}>
          <TextField
            
            
            label="Nome do Grupo"
            type="text"
            sx={{ width: "100%",marginTop: "10px", '& .MuiInputLabel-root': { color: theme.palette.mode === "dark" ? "white" : "black" }, '& .MuiInputBase-input': { color: theme.palette.mode === "dark" ? "white" : "black'" } }}
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <input
            type="file"
            style={{ display: 'block' }}
            onChange={handleLogoChange}
            accept="image/png, image/jpeg"

          />

          {newGroupLogo !== "" && <img src={newGroupLogo} alt="Logo do Grupo" style={{ maxWidth: "300px" }} />}


          
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={handleClose}>Cancelar</Button>
          <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={handleAddGroup}>Adicionar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SetupView;
