import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage for web
import sessionStorage from "redux-persist/lib/storage/session"; // sessionStorage
import companySlice from "./slices/companyslice";
import digitalTwinSlice from "./slices/digitalTwinSlice";
import themeSlice from "./slices/themeSlice";
import digitalTwinsListSlice from "./slices/digitalTwinsListSlice";
import chartsSlice from "./slices/chartsSlice";
import companiesSlice from "./slices/companiesSlice";
import sensorsSlice from "./slices/sensorsSlice";
import userSlice from "./slices/userSlice";
import analyticsSlice from "./slices/analyticsSlice";
import monitoringSlice from "./slices/monitoringSlice";
import dbSlice from "./slices/dbSlice";


// Function to create persisted reducer with given storage
const createPersistedReducer = (key, reducer, storageType) =>
  persistReducer({ key, storage: storageType, blacklist: [] }, reducer);

const sessionStorageReducers = {
  digitalTwinsList: digitalTwinsListSlice,
  charts: chartsSlice,
  companies: companiesSlice,
  sensors: sensorsSlice,
  user: userSlice,
  analytics: analyticsSlice,
  monitoring: monitoringSlice,
  db: dbSlice,
 
  
  // Add sessionStorage reducers here
};

// Combine all other reducers which use localStorage
const localStorageReducers = {
  company: companySlice,
  digitalTwin: digitalTwinSlice,
  theme: themeSlice,
  // Add localStorage reducers here
};

const sessionStoragePersistedReducers = Object.entries(
  sessionStorageReducers
).reduce((acc, [key, reducer]) => {
  acc[key] = createPersistedReducer(key, reducer, sessionStorage);
  return acc;
}, {});

const rootReducer = combineReducers({
  ...localStorageReducers,
  ...sessionStoragePersistedReducers,
});

const persistedReducer = persistReducer(
  { key: "root", storage, blacklist: Object.keys(sessionStorageReducers) }, // Exclude sessionStorage reducers from localStorage
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
