import React, { useState } from 'react';
import { Button, Card, CardMedia, useTheme, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import "./GroupCard.css";

interface GroupCardProps {
  group: {
    id: number;
    name: string;
    logo: string;
  };
  onDelete: (id: number) => void;
}

const GroupCard = ({ group, onDelete }: GroupCardProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();
  
  const [activeMenu, setActiveMenu] = useState(false);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(!activeMenu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMenu(false);

  };

  const handleEdit = () => {
    navigate(`/setup/${group.id}`);
    handleMenuClose();
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = () => {
    onDelete(group.id);
    setOpenDeleteDialog(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Card
        sx={{
          cursor: "pointer",
          height: "273px",
          width: "273px",
          display: "flex-column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          backgroundColor: theme.palette.mode === "dark" ? "#323238" : "white",
          color: theme.palette.mode === "dark" ? "white" : "#323238",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            display: "flex",
            width: "273px",
            height: "213px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <div style={{ width: "200px", margin: "auto" }}>
            <CardMedia
              component="img"
              image={group.logo || 'https://www.horizon-fm.fr/wp-content/uploads/2020/05/cropped-LogoVDE-1.png'}
              alt={group.name}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            height: "60px",
            justifyContent: "space-around",
            padding: "0px",
            alignItems: "center",
          }}
        >
          {group.name}
        </div>
        <IconButton
          id="basic-button"
          aria-controls={activeMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={activeMenu ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu

          id="basic-menu"
          anchorEl={anchorEl}
          open={activeMenu}

          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }} onClick={handleEdit}>Editar</MenuItem>
          <MenuItem onClick={handleDelete}>Deletar</MenuItem>
          
        </Menu>
      </Card>

      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle sx={{ color: "white", backgroundColor: "#124251" }}>Confirmação de Deleção</DialogTitle>
        <DialogContent sx={{ margin: "10px" }}>
          <Typography sx={{ color: theme.palette.mode === "dark" ? "white" : "#323238" }}>Tem certeza que deseja deletar o grupo <b style={{ color: theme.palette.mode === "dark" ? "red" : "#124251" }}>{group.name}</b>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "white" , backgroundColor: 'red', '&:hover': { backgroundColor: 'red', opacity: '0.8' } }} onClick={handleDeleteCancel}>Cancelar</Button>
          <Button sx={{ color: "white" , backgroundColor: "#124251", '&:hover': { backgroundColor: "#124251", opacity: '0.8' } }} onClick={handleDeleteConfirm} color="secondary">Apagar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupCard;
