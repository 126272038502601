import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import "./CompanyCard.css";
import { useState } from "react";

const CompanyCard = (props: any) => {

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(true);
  };

  
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const theme = useTheme();
  return (
    <div
      className={`CompanyCard ${props.selected ? "selected" : ""}`}
      
      style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}
    >
<Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza de que deseja excluir esta empresa? Esta ação é permanente e não pode ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
            props.onDelete();
            setOpenConfirmDialog(false);
          }} color="secondary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "flex", flexDirection: "row",gap:'10px',alignItems:'center' }}>
      <img src={props.company.complement || "https://apuissance3.com/wp-content/uploads/2021/04/chemical-factory-icon-53430.png"} alt="Avatar" style={{ maxWidth: "100px" }} />
      <p style={{ color: theme.palette.mode === "dark" ? "white" : "#323238",fontSize:'20px' }}>{props.company.name}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row",gap:'10px' }}>
       
        <button onClick={props.onSelect}
        style={{color: "white" ,backgroundColor: "blue",padding:'10px',height:'30px',width:'100px',display:'flex',justifyContent:'center',alignItems:'center'}}>
          Editar
        </button>
        <button  onClick={ () => handleConfirmDelete()}
        style={{color: "white" ,backgroundColor: "red",padding:'10px',height:'30px',width:'100px',display:'flex',justifyContent:'center',alignItems:'center' }}>
          Deletar
        </button>
      </div>
    </div>
  );
};
export default CompanyCard;
