const HistoricDetailsGraph = (
  data: Array<any>,
  start: any,
  end: any,
  title: any,
  theme: boolean
) => {

  const COLORS_DARK = [ "#F0E442","#7852E0", "#D55E00", "#009E73", "#fff"];
  const COLORS = [
    "rgb(18, 66, 81)",
    "#98CBFF",
    "#548BBB",
    "#009E73",
    "#000000",
    "#F0E442",
    "#0072B2",
    "#D55E00",
    "#CC79A7",
  ];

  const units = new Set();

  const series = data.map((e, i) => {
    units.add(e.yAxis);

    const unitsArray: any = Array.from(units);

    return {
      ...e.series,
      data: e.series.data,
      yAxisIndex: unitsArray.findIndex((obj) => obj.name === e.yAxis.name),

      showSymbol: false,
      symbolSize: 5,
      symbol: "circle",
      name: e.series.name,
      type: e.series.type,
      step: e.series.step,
      color: theme ? COLORS_DARK[i] : COLORS[i],
      smooth: false,
    };
  });

  const unitsArray: any = Array.from(units).filter((obj: any, index, self) => {
    return index === self.findIndex((o: any) => o.name === obj.name);
  });

  const options = {
    backgroundColor: "transparent",
    title: {
      text: title,
      textStyle: {
        fontWeight: 500,
        fontSize: "18px",
        fontFamily: "Roboto",
        
        wordWrap: "break-word",
      },
    },
    xAxis: {
      type: "time",
      max: new Date(end),
      min: new Date(start),
    },
    yAxis: unitsArray.map((unit: any, i) => {
      return {
        name: unit.name,
        type: unit.type,
        scale: true,
        position: i === 0 ? "left" : "right",
        alignTicks: true,
        axisTick: unit.axisTick,
        axisLine: unit.axisLine,
        axisLabel:{
          color: theme ? "#fff" : "#000",
        },
         
        
      };
    }),

    dataZoom: [
      {
        type: "inside",
      },
    ],
    toolbox: {
      show: true,
      orient: "horizontal",
      right: 30,
      top: 0,
      feature: {
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Voltar",
          },
          yAxisIndex: "all",
          brushStyle: {
            borderWidth: 1,
            color: "rgba(255, 173, 17, 0.4)",
            borderColor: "rgba(255, 173, 17, 1)",
          },
        },
        saveAsImage: {
          pixelRatio: 4,
          title: "Baixar .png",
          name: "Dados Históricos",
        },
        magicType: {
          title: {
            line: "Linha",
            bar: "Barra",
          },
          type: ["line", "bar"],
        },
      },
    },

    brush: {
      xAxisIndex: "all",
      brushLink: "all",
      outOfBrush: {
        colorAlpha: 0.1,
      },
      brushType: "rect",

      brushStyle: {
        borderWidth: 1,
        color: "rgba(255, 173, 177, 0.4)",
        borderColor: "rgba(255, 173, 177, 1)",
      },
      removeOnClick: true,
      inBrush: {
        color: "red",
      },
    },
    tooltip: {
      trigger: "axis",
      symbolSize: 5,
      symbol: "circle",
      showSymbol: true,
      itemWidth: 10,
      itemHeight: 10,
      hideDelay: 5,
      transitionDuration: 2,
      confine: true,
      axisPointer: {
        label: {},
      },
      valueFormatter: (value) =>
        parseFloat(value.toFixed(2)).toLocaleString("pt-BR"),
    },

    legend: {
      orient: "horizontal",
      bottom: 0,

      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
      right: 80,
      left: 60,
      top: 70,
      bottom: 50,
    },
    series: series,
  };

  return options;
};

export default HistoricDetailsGraph;
