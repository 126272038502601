import axios from "axios";
import { useEffect } from "react";
import GroupCardWithCompanies from "../../Components/Cards/GroupCard/GroupCardWithCompanies";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies, setCompaniess } from "../../store/slices/companiesSlice";




const ChooseCompanyView = () => {
 
  const dispatch = useDispatch<any>();
  const storedCompanies = useSelector(getCompanies);

  

  
  useEffect(() => {
    axios.get("/groupsWithCompanies").then((response) => {
      
      dispatch(setCompaniess(response.data));
      console.log(response.data)
      // console.log(response.data)
     
    });
    // dispatch(setCharts([]));
    // dispatch(resetDigitalTwinsList());
  }, [dispatch]);

  

  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px",
        gap: "10px",
       
      }}
    >
      
      {storedCompanies.map((group, i) => {
        return <GroupCardWithCompanies key={i} group={group} />
      })}
    </Box>
  );
};
export default ChooseCompanyView;
