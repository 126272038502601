
import { AvatarGroup, Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography, styled } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {  useTheme, Theme, CSSObject } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
// import { useRef}  from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MuiDrawer from '@mui/material/Drawer';
import logoInForma from "../../Assets/Images/logo-In-Forma.png";

import LogoUtil from "../../Assets/Icons/LogoUtil.svg";


import paths from "../../router/paths";
import DataService from "../../Services/DataService";
import { createInitials, getRandomColor } from "../../Utils/avatarUtils";
import { getCompanyId, resetCompany } from "../../store/slices/companyslice";
import FeedbackModal from "../Modals/FeedbackModal";
import NotificationBadge from "../NotificationBadge/NotificationBadge";
import { getIsAdmin, getUser, resetUser ,setUser } from "../../store/slices/userSlice";
import { setCompany , getCompany } from "../../store/slices/companyslice";

import { resetDigitalTwin } from "../../store/slices/digitalTwinSlice";
import SectionTitle from "./SectionTitle";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Back from '@mui/icons-material/ArrowBack';
import Help from '@mui/icons-material/HelpOutline';
import Note from '@mui/icons-material/Textsms';

import ThemeToggleButton from "../ThemeToggleButton";
import MenuIcon from '@mui/icons-material/Menu';
import FactoryIcon from '@mui/icons-material/Factory';
import DvrIcon from '@mui/icons-material/Dvr';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';


const TopBar = ({children, ...props}) => {
  const navigate = useNavigate();
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const user = useSelector(getUser);
  const isAdmin = useSelector(getIsAdmin);
  const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);
  const profileMenu =  Boolean(anchorProfile);
  const [logo, setLogo] = useState<any>(company?.logo);

  const drawerWidth = 300;

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
 
  const dispatch = useDispatch();
 
  

  const [userProfileInfo, setUserProfileInfo] = useState({
    initials: "",
    color: null,
  });
  const [feedback, setFeedback] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<any>(false);
 
 
 
  
  const [anchorDrawer, setAnchorDrawer] = useState<null | HTMLElement>(null);
 
  const [qtdGemeosOk, setQtdGemeosOk] = useState<any>(0);
  const [qtdGemeosAlertado, setQtdGemeosAlertado] = useState<any>(0);
  const [qtdGemeosAlarmado, setQtdGemeosAlarmado] = useState<any>(0);

  
  const theme = useTheme();
  const location = useLocation();


  const toggleDrawer = () => {
    setAnchorDrawer(anchorDrawer ? null : anchorDrawer);
    setOpenDrawer(!openDrawer);
  };

  const closeDrawer = () =>{
    setAnchorDrawer(null);
    setOpenDrawer(false);
  }

  const startMenuItems = [
    user.permissionLevel === "in_forma" ? { text: 'Painel Informa', link: '/inFormaPanel' , icon:<FactoryIcon/>}: { text: 'Painel', link: '/painel' , icon:<FactoryIcon/>},
  ];


  const openMenuItems = [
   
    { text: 'Monitoramento', link: '/monitoramento' ,icon:<DvrIcon/> },
    { text: 'Historico de Recomendacoes', link: '/historico-recomendacoes', icon:<ReportProblemOutlinedIcon/>},
    { text: 'Historico de Dados', link: '/historico-dados', icon:<ManageSearchOutlinedIcon/> },

    

  ];

  const adminMenuItems = [
    // { text: 'Painel Informa', link: '/inFormaPanel' , icon:<FactoryIcon/>},
    { text: 'Análise', link: '/analytics/1', icon:<TroubleshootOutlinedIcon/> },
    { text: 'Configurações', link: '/setup', icon:<HandymanOutlinedIcon/>},
    

  ];



  const renderMenuItems = () => (
    <Box
     
      role="presentation"
      onClick={closeDrawer}
      
    >
      <List>
      {startMenuItems.map((item, index) => (
         <ListItem key={item.text} disablePadding sx={{ display: 'block' }} title={item.text} onClick={() => navigate(item.link)}>
          
         <ListItemButton
           sx={{
             minHeight: 48,
             justifyContent: openDrawer ? 'initial' : 'center',
             px: 2.5,
           }}
         >
           <ListItemIcon
            
             sx={{
               color: theme.palette.secondary.contrastText,
               minWidth: 0,
               mr: openDrawer ? 3 : 'auto',
               justifyContent: 'center',
             }}
           >
            
             {item.icon}
           </ListItemIcon>
           <ListItemText primary={item.text} sx={{ opacity: openDrawer ? 1 : 0 }} />
         </ListItemButton>
         
       </ListItem>
        ))}
        {openMenuItems.map((item, index) => (
         <ListItem key={item.text} disablePadding sx={{ display: 'block' }} title={item.text} onClick={() => navigate(item.link)}>
          
         <ListItemButton
           sx={{
             minHeight: 48,
             justifyContent: openDrawer ? 'initial' : 'center',
             px: 2.5,
           }}
         >
           <ListItemIcon
            
             sx={{
               color: theme.palette.secondary.contrastText,
               minWidth: 0,
               mr: openDrawer ? 3 : 'auto',
               justifyContent: 'center',
             }}
           >
            
             {item.icon}
           </ListItemIcon>
           <ListItemText primary={item.text} sx={{ opacity: openDrawer ? 1 : 0 }} />
         </ListItemButton>
         
       </ListItem>
        ))}
        {user.permissionLevel === "in_forma" && adminMenuItems.map((item, index) => (
         <ListItem key={item.text} disablePadding sx={{ display: 'block' }} title={item.text} onClick={() => navigate(item.link)}>
          
         <ListItemButton
           sx={{
             minHeight: 48,
             justifyContent: openDrawer ? 'initial' : 'center',
             px: 2.5,
           }}
         >
           <ListItemIcon
            
             sx={{
               color: theme.palette.secondary.contrastText,
               minWidth: 0,
               mr: openDrawer ? 3 : 'auto',
               justifyContent: 'center',
             }}
           >
            
             {item.icon}
           </ListItemIcon>
           <ListItemText primary={item.text} sx={{ opacity: openDrawer ? 1 : 0 }} />
         </ListItemButton>
         
       </ListItem>
        ))}
      </List>
    </Box>
  );

  // const uploadFile = async () => {
  //   if (!selectedFile) return;
  
  //   const formData = new FormData();
  //   formData.append('file', selectedFile);
  
  //   try {
  //     const response = await axios.post('/api/upload', formData);
  //     console.log('Arquivo enviado com sucesso:', response.data);
  //     // Aqui você pode atualizar o estado do usuário no frontend com a nova imagem,
  //     // ou simplesmente recarregar as informações do usuário para refletir a mudança.
  //   } catch (error) {
  //     console.error('Erro ao enviar arquivo:', error);
  //   }
  // };
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  // const fileInputRef : any = useRef(null);

  // const handleUploadClick = () => {
  //   fileInputRef.current.click()
  // };

 

  const handleFileChange = async(e) => {
    const file = e.target.files[0];
    
    setSelectedFile(file);

    // Preview da imagem
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState('');
 
  
  const handleLogoChange = async(e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }
  // const handleUploadClick = () => {
  //   fileInputRef.current.click(); // Abre a caixa de diálogo de seleção de arquivo
  // };
  
  useEffect(() => {
    if (isAdmin) {
      setLogo(logoInForma);
    } else if (company.companies && company.companies.length > 0) {
      setLogo(company.companies[0].complement);
    }
  }, [company.companies, isAdmin]);

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   handleCloseProfileMenu(); // Fecha o menu após selecionar o arquivo (opcional)
  // };
  

  interface AppBarProps extends MuiAppBarProps {
    openDrawer: boolean;
    }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, openDrawer }) => ({
    zIndex: theme.zIndex.drawer + 1,
    elevation: 0,
    '& .MuiToolbar-root': {
      padding: '0px 0px 0px 10px',
      margin: '0px',
      
    },
    '& .MuiAppBar-root': {
      padding: '0px',
      margin: '0px',
    },
   
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(openDrawer && {
      marginLeft: drawerWidth,
      
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
   
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));


  

 

  

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const logout = () => {
    dispatch(resetUser());
    dispatch(resetCompany());
    dispatch(resetDigitalTwin());
  };

  useEffect(() => {
    setQtdGemeosOk(0);
    setQtdGemeosAlertado(0);
    setQtdGemeosAlarmado(0);
  }, []);

  useEffect(() => {
    if (userProfileInfo.color === null) {
      setUserProfileInfo((userProfileInfo) => ({
        ...userProfileInfo,
        color: getRandomColor(),
      }));
    }

    if (userProfileInfo.initials === "") {
      const initials = createInitials(user.name);
      if (initials !== null && initials !== undefined) {
        setUserProfileInfo((userProfileInfo) => ({
          ...userProfileInfo,
          initials,
        }));
      }
    }
  }, [user.name, userProfileInfo]);

  useEffect(() => {
    if (companyId) {
      DataService.getCompanyTwinsStatus(companyId).then((res) => {
        setQtdGemeosOk(res.normal);
        setQtdGemeosAlertado(res.alert);
        setQtdGemeosAlarmado(res.alarm);
      });
    }



    const interval = setInterval(() => {
      if (companyId) {
        DataService.getCompanyTwinsStatus(companyId).then((res) => {
          setQtdGemeosOk(res.normal);
          setQtdGemeosAlertado(res.alert);
          setQtdGemeosAlarmado(res.alarm);
        });
      }
    }, 180000);


    return () => {
      clearInterval(interval);
    };
  }, [companyId, location.pathname]);

  

  const [selectedFile, setSelectedFile] = useState(null);

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProfile(event.currentTarget as HTMLElement);
    
  };

  const [anchorLogo,setAnchorLogo] = useState<null | HTMLElement>(null);
  const [openLogo, setOpenLogo] = useState(false);

  const handleOpenLogoMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLogo(event.currentTarget as HTMLElement);
    setOpenLogo(true);
  }

  const handleCloseProfileMenu = () => {
    setAnchorProfile(null);
  };

  const handleCloseLogoMenu = () => {
    setAnchorLogo(null);
    setOpenLogo(false);
  }

  const handleSaveProfile = () => {
    setSelectedFile(null);
    handleCloseProfileMenu();
    
    dispatch(setUser({ ...user, perfil: imagePreviewUrl }));
    DataService.setUserProfile({ ...user, perfil: imagePreviewUrl })
  };
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null)
  const handleSelectCompany = (index) => {
    setSelectedCompanyIndex(index);
    // Qualquer outra lógica relacionada à seleção da empresa
  };

  const handleSaveLogo = () => {
    if (selectedCompanyIndex === null) {
      // Lidar com o caso onde nenhuma empresa está selecionada
      return;
    }
  
    dispatch(setCompany({ ...company, complement: logoPreviewUrl }));
    DataService.setCompanyLogo( { ...company, complement: logoPreviewUrl },selectedCompanyIndex);
    setSelectedLogo(null);
    handleCloseLogoMenu();
    setLogo(logoPreviewUrl);
  };

  const handleCancelProfile = () => {
    setImagePreviewUrl(user.perfil ? user.perfil : null);
    setSelectedFile(null);
    handleCloseProfileMenu();
  };

  const handleCancelLogo = () => {
    setLogoPreviewUrl(user.logo ? user.logo : null);
    setSelectedLogo(null);
    handleCloseLogoMenu();
  };
  

  return (
    <>
     {feedback && (
        <FeedbackModal
          cancel={() => {
            setFeedback(false);
          }}
        />
      )}



      <AppBar openDrawer={openDrawer} elevation={0}  >
      
      <Toolbar >

      <Menu
            // ref={fileInputRef}
            
            anchorEl={anchorLogo}
            open={openLogo}
            onClose={handleCloseLogoMenu}
           sx={{
            //  backgroundColor: 'rgba(0, 0, 0, 0.5)',
             top: '50px',
            //  right: '10px',
            marginLeft: "auto",
            marginRight: "10px",
      width: "330px",
            position: 'absolute',
            left: '0px',
           }}
          >
            
            <input
        type="file"
        style={{ display: 'block' }}
        onChange={handleLogoChange}
      />
       {/* <Button  sx={{width: "100%"}} onClick={handleUploadClick}>{imagePreviewUrl ? (
        <div>
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) : "Selecionar imagem"}</Button> */}

{logoPreviewUrl && (
        <div>
          <img
            src={logoPreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) }


      {/* Renderize sua lista de empresas e passe o índice para `handleSelectCompany` */}
            <button onClick={() => handleSelectCompany(0)}>Selecionar Empresa 1</button>
            <button onClick={() => handleSelectCompany(1)}>Selecionar Empresa 2</button>

            {selectedLogo && <MenuItem  sx={{width: "100%"}} onClick={handleSaveLogo}>Salvar</MenuItem>}
            {selectedLogo &&  <MenuItem  sx={{width: "100%"}} onClick={handleCancelLogo}>Cancelar</MenuItem>}

            
          </Menu>
      <Menu
            // ref={fileInputRef}
            
            anchorEl={anchorProfile}
            open={profileMenu}
            onClose={handleCloseProfileMenu}
           sx={{
            //  backgroundColor: 'rgba(0, 0, 0, 0.5)',
             top: '50px',
            //  right: '10px',
            marginLeft: "auto",
            marginRight: "10px",
      width: "330px",
            position: 'absolute',
            left: '0px',
           }}
          >
            
            <input
        type="file"
        style={{ display: 'block' }}
        onChange={handleFileChange}
      />
       {/* <Button  sx={{width: "100%"}} onClick={handleUploadClick}>{imagePreviewUrl ? (
        <div>
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) : "Selecionar imagem"}</Button> */}

{imagePreviewUrl && (
        <div>
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
          />
        </div>
      ) }

       
            {selectedFile && <MenuItem  sx={{width: "100%"}} onClick={handleSaveProfile}>Salvar</MenuItem>}
            {selectedFile &&  <MenuItem  sx={{width: "100%"}} onClick={handleCancelProfile}>Cancelar</MenuItem>}

            
          </Menu>
          <IconButton
            title="Menu"
            size="large"
            edge="start"
            
            
            
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              ...(openDrawer && { display: 'none' }),
              
              justifyContent: 'center',
              borderRadius: "0",
              width: "67px",
              height: "67px",
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              
            }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>


          <img
            title="Logo Util"
            src={LogoUtil}
            alt="Logo Util"
            style={{ width: "50px", marginLeft: "10px", marginRight: "10px" }}

          />

            {location.pathname !== paths.PANEL_MONITORING &&
          location.pathname !== paths.PANEL_TWINS &&
          location.pathname !== paths.CHOOSE_COMPANY ? (
            <Back
              onClick={() => {
                window.history.back();
              }}
              style={{ }}
              titleAccess="Voltar"
            />
          ) : null}

          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 ,  textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
            <SectionTitle />
          </Typography>
          
       
      
        
      
        
          {location.pathname !== "/inFormaPanel" && (
            
            companyId && <Box onClick={() => navigate("/painel") } sx={{ cursor: "pointer" ,display: "flex", alignItems: "center"}}>
                  
                  
                        <NotificationBadge
                          // title="Normal"
                          type="greenBadge"
                          notificationNumber={qtdGemeosOk}
                          />
                        <NotificationBadge
                          // title="Alerta"
                          type="yellowBadge"
                          notificationNumber={qtdGemeosAlertado}
                        />
                        <NotificationBadge
                          // title="Alarme"
                          type="redBadge"
                          notificationNumber={qtdGemeosAlarmado}
                        />

                       
                        
                        
                        
                        
                        
                        </Box>
                        
                      )}

                      <Divider style={{ marginRight: "10px" }} />
                      
                    

          <ThemeToggleButton />
           
          
          <Note
            titleAccess="Feedback"
            
            style={{  cursor: "pointer",marginRight: "8px"}}
            onClick={() => {
              setFeedback(true);
            }}
          />
            
              
            
          
          
            
              <Help  
              onClick={() => {
                navigate("/faq");
              }}
              titleAccess="Ajuda(FAQ)"
              style={{ cursor: "pointer"}} 
              />
             
          

          
          <Divider style={{ marginRight: "10px" }} /> 


          <img
                title={company?.name}
                className="logoimg"
                src={logo}
                alt="Logo da compania"
                style={{ maxHeight: "60px", width: "60px", cursor: "pointer" }}
                onClick={(event) => handleOpenLogoMenu(event)}
              />

            <Divider style={{ marginRight: "10px" }} />
          
          
            <AvatarGroup max={3}>
              
              <Avatar
              id="userProfile"
                title={user.name}
                sx={{
                  width: "44px",
                  height: "44px",
                  bgcolor: userProfileInfo.color,
                  cursor: "pointer",
                }}
                onClick={(event) => handleOpenProfileMenu(event)}
              >
               {user.perfil ? 
                <img
                style={{ borderRadius: "50%", height: "100%", width: "100%" }}
                src={user.perfil}
                alt={user.name}
               
              /> : userProfileInfo.initials} 
              </Avatar>
            </AvatarGroup>
            <Divider style={{ marginRight: "10px" }} />
        

           
          <IconButton
            title="Sair"
            size="large"
            edge="start"
           
            
            sx={{
             
              cursor: "pointer",
              
            }}
            onClick={() => {
              navigate('/login');
              logout()
            }}
          >
            
            <ExitToAppOutlinedIcon />
          
          </IconButton>
        
          
            
    
     
            
      </Toolbar>

      </AppBar>
    <Box  
    // sx={{ display: 'grid', gridTemplateColumns: '1fr' }} 
     sx={{ position: "fixed", top: "64px",left: "67px",height: "calc(100% - 0px)", width: "100%",overflow: "auto"}} 
    >

      

      {children}

       


        
    </Box>

       <Drawer
       variant="permanent"
       
           open={openDrawer}
           onClose={toggleDrawer}
           sx={{
             
             '& .MuiDrawer-paper': {
               boxSizing: 'border-box',
               backgroundColor: theme.palette.secondary.main,
               color: theme.palette.secondary.contrastText
               
               
             },
           }}
     >
         
         <DrawerHeader>
         <IconButton onClick={handleDrawerClose} sx={{ color: theme.palette.secondary.contrastText }}>
           {theme.direction === 'rtl' ? <ChevronRightIcon color="inherit" /> : <ChevronLeftIcon color="inherit"/>}
         </IconButton>
       </DrawerHeader>
       {renderMenuItems()}
       </Drawer>

      </>
    
  );
};

export default TopBar;
