import { createSlice } from "@reduxjs/toolkit";

interface ICompany {
  id: string;
  name: string;
  complement: string;
}

interface UserData {
  token: string;
  id: string;
  name: string;
  email: string;
  permissionLevel: string;
  logo: string;
  perfil: string;
  companies?: ICompany[];
}

interface UserState {
  data: UserData;
}

const initialState: UserState = {
  data: {
    token: "",
    id: "",
    name: "",
    email: "",
    permissionLevel: "",
    logo: "",
    perfil: "",
    companies: [],
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    setToken: (state, action) => {
      state.data.token = action.payload;
    },
    resetUser: (state) => {
      state.data = { ...initialState.data };
    },
  },
});

export const getUser = (state) => state.user.data;
export const getUserName = (state) => state.user.data.name;
export const getUserId = (state) => state.user.data.id;
export const getUserLogo = (state) => state.user.data.logo;
export const getIsAdmin = (state) =>
  state.user.data.permissionLevel === "in_forma";
export const getIsLoggedIn = (state) => state.user.data.token.length > 0;

export const { setUser, setToken, resetUser } = userSlice.actions;
export default userSlice.reducer;
