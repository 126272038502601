import { useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewUserForm.css";

// interface newUserInterface {
//   name: string;
//   email: string;
//   password: string;
//   permition_level: string;
// }

const NewUserForm = (props: any) => {
  const [newUser, setNewUser] = useState(props.user);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await DbService.edit_user(
      newUser.id,
      newUser.name,
      newUser.email,
      newUser.permition_level
    );
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
    }
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="form-div-input">
        <input
          id="name"
          name="name"
          type="text"
          onChange={onChange}
          value={newUser.name}
          placeholder="Digite o nome do funcionario"
        />
        <label htmlFor="name">Nome</label>
      </div>
      <div className="form-div-input">
        <input
          id="email"
          name="email"
          type="text"
          onChange={onChange}
          value={newUser.email}
          placeholder="Digite o email do funcionario"
        />
        <label htmlFor="email">E-mail</label>
      </div>
      <div className="form-div-select">
        <select
          id="permition_level"
          name="permition_level"
          onChange={onChange}
          required
          value={newUser.permition_level}
        >
          <option value="" disabled selected hidden>
            Selecione o nivel de permissão do funcionario
          </option>
          <option value="admin">administrador</option>
          <option value="basic">básico</option>
        </select>
        <label htmlFor="permition_level">Nivel de permissão</label>
      </div>
      
      <div className="buttons">

        
        <button className="delete" onClick={props.cancel}>
          Cancelar
        </button>
        <button className="submmit">Salvar Usuario</button>
        <button
          className="delete"
          onClick={(event) => {
            event.preventDefault();
            props.onClickDelete();
          }}
        >
          Remover
        </button>
      </div>
      
    </form>
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px',gap: '10px'}}> 
    {props.user?.is_active === false && <button  onClick={props.activateUser}>Ativar</button>}
     
    </div>
      </>
  );
};
export default NewUserForm;
