import * as ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store/";
import axios from "axios";
import { PersistGate } from "redux-persist/es/integration/react";





axios.defaults.baseURL = process.env.REACT_APP_API_URL;


ReactDOM.render(
  
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById("root")
);

reportWebVitals();
