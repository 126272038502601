import LastRecommendationCard from "./LastRecomendation/LastRecommendationCard";

import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { Accordion, AccordionDetails, AccordionSummary, Box,  List,  ListItem, Paper, useTheme } from "@mui/material";

import Modal from "../../Views/MonitoringView/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import { useSelector } from "react-redux";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";

import paths from "../../router/paths";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const CardAlertas = () => {
  const navigate = useNavigate();
  const digitalTwin = useSelector(getDigitalTwin);

  const location = useLocation();
  const isMonitoringPage = location.pathname === '/monitoramento';

  const [alertas /* , setAlertas */] = useState<any>([]);
  const [alarmes, setAlarmes] = useState<any>([]);
  const [allRecomendations, setAllRecomendations] = useState<any>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [aux, setAux] = useState(false);
  const [timestamp, setTimestamp] = useState<any>(moment());
  const [time, setTime] = useState<any>(0);

  const ordenate = (recomendations: any) => {
    recomendations.sort((a, b) => {
      if (moment(a.first_alert_time).isBefore(moment(b.first_alert_time)))
        return 1;
      if (moment(a.first_alert_time).isAfter(moment(b.first_alert_time)))
        return -1;
      return 0;
    });
    return recomendations;
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(moment());
      if (!aux) {
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [aux]);

  useEffect(() => {
    const interval = setInterval(() => {
      time > 0 && setTime(time - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AlertsAlarmsService.getAlarmsRange(
          digitalTwin.id,
          moment().subtract(7, "days"),
          moment()
        );

        if (!isEqual(res, alarmes)) {
          setAlarmes(res);
        }
      } catch (error) {
        // Lide com erros, se necessário
        console.error("Erro ao buscar alarmes:", error);
      }
    };

    // Chamada inicial ao montar o componente
    fetchData();

    // Configuração do intervalo com verificação inteligente
    const interval = setInterval(() => {
      fetchData();
    }, 180000);

    // Limpeza do intervalo quando o componente é desmontado
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [digitalTwin.id, alarmes]);

  useEffect(() => {
    if (alertas.length !== 0 || alarmes.length !== 0) {
      const allRecomendationsFetched = [...alertas, ...alarmes];
      setAllRecomendations(ordenate(allRecomendationsFetched));
    }
    return () => {};
  }, [alertas, alarmes]);

  useEffect(() => {
    if (
      allRecomendations[0] !== undefined &&
      !allRecomendations[0].acknowledged &&
      allRecomendations[0].finished === false
    ) {
      setAux(true);
    }

    if (
      allRecomendations[0] !== undefined &&
      !allRecomendations[0].acknowledged &&
      allRecomendations[0].finished === true
    ) {
      setAux(true);
    }

    return () => {
      setAux(false);
    };
  }, [allRecomendations, aux]);

  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
    <Modal
      timestamp={timestamp}
      alerta={allRecomendations[0] !== undefined && allRecomendations[0]}
      gemeo={digitalTwin}
    />
    <Box sx={{ display: "flex", flexDirection: "row" , width: "calc(100% - 67px)", justifyContent:'space-around', margin: "0px", padding: "0px",alignItems: "start" }}>
    <Accordion expanded={expanded} onChange={handleChange} elevation={0} sx={{ width: "100%" ,padding: "0px", justifyContent:'space-between', margin: "0px"}}>
    <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: "0px 10px" }}

        >
       <Box sx={{ display: "grid ", gridTemplateColumns: "1fr", padding: "0px", justifyContent:'space-between' ,width:'100%'}}>
      
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          width: "100%",
          padding: "0px",
        }}
        
        
      >
        {allRecomendations[0] === undefined && (
          
            <LastRecommendationCard
              type={""}
              title={"Sem recomendacões"}
              descrição={"Não foram encontradas recomendacões recentes"}
              periodo={""}
              timer={""}
              eficiencia={""}
              id={""}
              alerta={""}
            />
      
        )}

        {allRecomendations[0] !== undefined &&
          !allRecomendations[0].predictions && (
           
              <LastRecommendationCard
                type={
                  allRecomendations[0].finished ? "Solucionado" : "recomendação"
                }
                title={digitalTwin.name}
                descrição={
                  allRecomendations[0].finished
                    ? "Eficiência do consumo de Gás Natural dentro do intervalo aceitavel"
                    : "Eficiência do consumo de Gás Natural fora do intervalo aceitavel"
                }
                periodo={
                  allRecomendations[0].finished
                    ? `${new Date(
                        allRecomendations[0].last_alert_time
                      ).toLocaleDateString()}-${new Date(
                        allRecomendations[0].last_alert_time
                      ).toLocaleTimeString()}`
                    : `${new Date(
                        allRecomendations[0].first_alert_time
                      ).toLocaleDateString()}-${new Date(
                        allRecomendations[0].first_alert_time
                      ).toLocaleTimeString()}`
                }
                timer={
                  allRecomendations[0].finished
                    ? `${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .days()}d ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .hours()}h ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .minutes()}m ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .seconds()}s`
                    : `${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .days()}d ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .hours()}h ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .minutes()}m ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .seconds()}s`
                }
                eficiencia={
                  allRecomendations[0].finished
                    ? parseFloat(allRecomendations[0]?.last_value) < 0 ? 0 : parseFloat(allRecomendations[0]?.last_value).toFixed(2)
                    : parseFloat(allRecomendations[0]?.first_value).toFixed(2)
                }
                id={allRecomendations[0].id}
                alerta={allRecomendations[0]}
              />
            
          )}


        {allRecomendations[0] !== undefined &&
          allRecomendations[0].predictions && (
          
              <LastRecommendationCard
                type={"alerta"}
                title={digitalTwin.name}
                descrição={
                  "Eficiência do consumo de Gás Natural sairá do intervalo aceitavel"
                }
                periodo={
                  allRecomendations[0].finished
                    ? `${new Date(
                        allRecomendations[0].last_alert_time
                      ).toLocaleDateString()}-${new Date(
                        allRecomendations[0].last_alert_time
                      ).toLocaleTimeString()}`
                    : `${new Date(
                        allRecomendations[0].first_alert_time
                      ).toLocaleDateString()}-${new Date(
                        allRecomendations[0].first_alert_time
                      ).toLocaleTimeString()}`
                }
                timer={
                  allRecomendations[0].finished
                    ? `${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .days()}d ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .hours()}h ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .minutes()}m ${moment
                        .duration(
                          moment(allRecomendations[0].last_alert_time).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .seconds()}s`
                    : `${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .days()}d ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .hours()}h ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .minutes()}m ${moment
                        .duration(
                          moment(timestamp).diff(
                            moment(allRecomendations[0].first_alert_time)
                          )
                        )
                        .seconds()}s`
                }
                eficiencia={allRecomendations[0]?.recomendation[
                  "eficiência atual"
                ].toFixed(2)}
                id={allRecomendations[0].id}
                alerta={allRecomendations[0]}
              />
            
          )}

      </Paper>

{/*       
        <button
            style={{ textTransform: "none", width: "100%",margin: "0px",padding: "10px", whiteSpace: 'nowrap',backgroundColor: theme.palette.background.default ,color: theme.palette.text.primary }}
            onClick={(event : any) => {
              setAnchorEl(event.currentTarget);
              setOpen(true);
            }}
          
        >
        Ver Mais 
        
        </button> */}

       
      

      
    </Box>
    </AccordionSummary>
    <AccordionDetails sx={{ display: "flex", flexDirection: "column",margin: "0px",padding: "20px" }} onChange={handleChange}  >
      
       
          <Box
            style={{
             
              display: "flex",
              flexDirection: "row",
              
              
              
             
              fontSize: "18px",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            Recomendações {digitalTwin.name}
          </Box>

         
            <List sx={{ flexGrow: 1}}>

              {allRecomendations.map((alerta) => {
                return (
                  <ListItem
                    sx={{ width: "100%" }}
                    key={alerta.id}
                    onClick={() => {
                      setExpanded(false)
                      navigate(paths.HISTORICO_RECOMENDACOES, {
                        state: {
                          alertId: alerta.id,
                        },
                      });
                      
                    }}
                  >

                    {alerta.finished && (
                      <Box
                       sx={{
                         display: "flex",
                         flexDirection: "column",
                         gap: "10px",
                         width: "100%",
                       }}
                       
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        {!alerta.predictions && <LastRecommendationCard
                              type={
                                alerta.finished ? "Solucionado" : "recomendação"
                              }
                              title={digitalTwin.name}
                              descrição={
                                alerta.finished
                                  ? "Eficiência do consumo de Gás Natural dentro do intervalo aceitavel"
                                  : "Eficiência do consumo de Gás Natural fora do intervalo aceitavel"
                              }
                              periodo={
                                alerta.finished
                                  ? `${new Date(
                                      alerta.last_alert_time
                                    ).toLocaleDateString()}-${new Date(
                                      alerta.last_alert_time
                                    ).toLocaleTimeString()}`
                                  : `${new Date(
                                      alerta.first_alert_time
                                    ).toLocaleDateString()}-${new Date(
                                      alerta.first_alert_time
                                    ).toLocaleTimeString()}`
                              }
                              timer={
                                alerta.finished
                                  ? `${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .days()}d ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .hours()}h ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .minutes()}m ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .seconds()}s`
                                  : `${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .days()}d ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .hours()}h ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .minutes()}m ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .seconds()}s`
                              }
                              eficiencia={
                                alerta.finished
                                  ? parseFloat(alerta?.last_value) < 0 ? 0 : parseFloat(alerta?.last_value).toFixed(2)
                                  : parseFloat(alerta?.first_value).toFixed(2)
                              }
                              id={alerta.id}
                              alerta={alerta}
                            />
                         
                        }

                        {!alerta.predictions && (
                          <Box
                            sx={{
                              paddingLeft: "50px"
                            }}
                          >
                            <LastRecommendationCard
                              type={"recomendação"}
                              title={digitalTwin.name}
                              descrição={
                                "Eficiência do consumo de Gás Natural fora do intervalo aceitavel"
                              }
                              periodo={`${new Date(
                                alerta.first_alert_time
                              ).toLocaleDateString()}-${new Date(
                                alerta.first_alert_time
                              ).toLocaleTimeString()}`}
                              timer={
                                alerta.finished
                                  ? `${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .days()}d ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .hours()}h ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .minutes()}m ${moment
                                      .duration(
                                        moment(alerta.last_alert_time).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .seconds()}s`
                                  : `${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .days()}d ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .hours()}h ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .minutes()}m ${moment
                                      .duration(
                                        moment(timestamp).diff(
                                          moment(alerta.first_alert_time)
                                        )
                                      )
                                      .seconds()}s`
                              }
                              eficiencia={parseFloat(
                                alerta?.first_value
                              ).toFixed(2)}
                              id={alerta.id}
                              alerta={alerta}
                            />
                          </Box>
                        )}
                      </Box>
                    )}

                    {!alerta.predictions && !alerta.finished && 
                    <LastRecommendationCard
                          type={
                            alerta.finished ? "Solucionado" : "recomendação"
                          }
                          title={digitalTwin.name}
                          descrição={
                            alerta.finished
                              ? "Eficiência do consumo de Gás Natural dentro do intervalo aceitavel"
                              : "Eficiência do consumo de Gás Natural fora do intervalo aceitavel"
                          }
                          periodo={
                            alerta.finished
                              ? `${new Date(
                                  alerta.last_alert_time
                                ).toLocaleDateString()}-${new Date(
                                  alerta.last_alert_time
                                ).toLocaleTimeString()}`
                              : `${new Date(
                                  alerta.first_alert_time
                                ).toLocaleDateString()}-${new Date(
                                  alerta.first_alert_time
                                ).toLocaleTimeString()}`
                          }
                          timer={
                            alerta.finished
                              ? `${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .days()}d ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .hours()}h ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .minutes()}m ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .seconds()}s`
                              : `${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .days()}d ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .hours()}h ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .minutes()}m ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .seconds()}s`
                          }
                          eficiencia={
                            alerta.finished
                              ? parseFloat(alerta?.last_value) < 0 ? 0 :parseFloat(alerta?.last_value).toFixed(2)
                              : parseFloat(alerta?.first_value).toFixed(2)
                          }
                          id={alerta.id}
                          alerta={alerta}
                        />

                    }

                    {alerta.predictions && (
                      <Box sx={{ paddingLeft: "50px" }}>
                        <LastRecommendationCard
                          type={"alerta"}
                          title={digitalTwin.name}
                          descrição={
                            "Eficiência do consumo de Gás Natural sairá do intervalo aceitavel"
                          }
                          periodo={
                            alerta.finished
                              ? `${new Date(
                                  alerta.last_alert_time
                                ).toLocaleDateString()}-${new Date(
                                  alerta.last_alert_time
                                ).toLocaleTimeString()}`
                              : `${new Date(
                                  alerta.first_alert_time
                                ).toLocaleDateString()}-${new Date(
                                  alerta.first_alert_time
                                ).toLocaleTimeString()}`
                          }
                          timer={
                            alerta.finished
                              ? `${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .days()}d ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .hours()}h ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .minutes()}m ${moment
                                  .duration(
                                    moment(alerta.last_alert_time).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .seconds()}s`
                              : `${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .days()}d ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .hours()}h ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .minutes()}m ${moment
                                  .duration(
                                    moment(timestamp).diff(
                                      moment(alerta.first_alert_time)
                                    )
                                  )
                                  .seconds()}s`
                          }
                          id={alerta.id}
                          eficiencia={alerta?.recomendation[
                            "eficiência atual"
                          ].toFixed(2)}
                          alerta={alerta}
                        />
                      </Box>
                    )}


                  </ListItem>
                );
              }).splice(0, 5)}
            </List>

            
        
     
      </AccordionDetails>
    </Accordion>
    <button
          style={{  
            margin: "10px", whiteSpace: 'nowrap',background: theme.palette.secondary.main ,color: theme.palette.secondary.contrastText, fontSize: "16px", fontFamily: "Roboto", fontWeight: 500, wordWrap: "break-word", padding: "10px", borderRadius: "6px", flexGrow: "1",
            ...(isMonitoringPage && {
              height: "3vh",
              display: "flex",
              alignItems: "center",
            })
          }}
          
          
          onClick={() => {
            navigate(paths.HISTORICO_RECOMENDACOES, {
              state: {
                alertId: allRecomendations[0]?.id,
              },
            });
          }}
          

        >
          Ver Detalhes
        </button>
        </Box>
    </>
  );
};

export default CardAlertas;
