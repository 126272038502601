import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAnalyticsChartsOn } from "../../store/slices/analyticsSlice";
import { CircularProgress } from "@mui/material";

interface GainInterface {
  chartRef: any;
  isDarkTheme: any;
  isAlarm?: boolean;
  isGain?: boolean;
}

const Gain = ({ chartRef, isDarkTheme, isAlarm, isGain=true }: GainInterface) => {
  const [gain, setGain] = useState("0");
  const [aMean, setAMean] = useState("...");
  const [rMean, setRMean] = useState("...");

  const chartsOnResults = useSelector(getAnalyticsChartsOn);

  useEffect(() => {
    const calculateGain = () => {
      const chartInstance = chartRef.current?.getEchartsInstance();
      const option = chartInstance?.getOption();

      if (!option || !option.series || option.series.length < 2) {
        return "0";
      }

      const actualValuesFrequency = option.series[0].data.map((data, idx) =>
        parseFloat(data[1])
      );
      const recommendedValuesFrequency = option.series[1].data.map(
        (data, idx) => parseFloat(data[1])
      );

      // Calculate the means
      const actualMean =
        actualValuesFrequency.reduce((a, b) => a + b, 0) /
        actualValuesFrequency.length;
        setAMean(actualMean.toFixed(2));
      const recommendedMean =
        recommendedValuesFrequency.reduce((a, b) => a + b, 0) /
        recommendedValuesFrequency.length;
        setRMean(recommendedMean.toFixed(2));

      // Calculate the percentual difference between means
      const percentualDifference =
        Math.abs(actualMean - recommendedMean) / actualMean;

      if (isNaN(percentualDifference) || !isFinite(percentualDifference)) {
        return "Dados Insuficientes";
      }

      return (percentualDifference * 100).toFixed(2) + "%";
    };

    const updateGain = () => {
      const newGain = calculateGain();
      setGain(newGain);
    };

    const chartInstance = chartRef.current?.getEchartsInstance();
    chartInstance?.on("finished", updateGain);

    return () => {
      chartInstance?.off("finished", updateGain);
    };
  }, [chartRef, isAlarm]);

  return (
    <div
      style={{
        // backgroundColor: isDarkTheme ? "#323238" : "#fdfdff",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 auto",
        marginTop: "2vh",
        // height: "50px",
        zIndex: 1,
      }}
    >
      {isGain ? (
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: gain === "Dados Insuficientes" ? "2vw" : "5vw",
            color: isDarkTheme ? "#fff" : "#124251",
            paddingLeft: "5vw",
          }}
        >
          {chartsOnResults ? (
            gain
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                
              }}
            >
              <CircularProgress
                sx={{
                  color: "#124251",
                }}
                size={25}
              />
              <p
                style={{
                  margin: "10px",
                  fontSize: "12px",
                  fontWeight: 450,
                }}
              >
                Carregando
              </p>
            </div>
          )}
        </span>
      ) : (
        <div style={{ display: "flex",flexDirection: 'row', alignItems: "center",gap: '10px',justifyContent: 'center',width: '40vw'}}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "600",
              color: isDarkTheme ? "#fff" : "#3cb2ef",
              width: '150px',
              // marginRight: "20px",
            }}
          >
            X̅ = {chartsOnResults ? aMean : "..."}
          </span>

          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "600",
              color: isDarkTheme ? "#fff" : "#ff0000",
              width: '150px',
            }}
          >
            X̅ = {chartsOnResults ? rMean : "..."}
          </span>
        </div>
      )}
    </div>
  );
};

export default Gain;
