import "./DeleteConfirmationDialog.css";

const DeleteConfirmationDialog = (props: any) => {
  return (
    <div className="DeleteConfirmationDialog">
      <div className="dialogBox">
        <div className="text">
          <p>{props.text}</p>
        </div>
        <div className="buttons">
          <button name="cancel" onClick={props.handleAction}>
            Cancelar
          </button>
          <button name="confirm" onClick={props.handleAction}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;