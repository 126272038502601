import axios from "axios";
import moment from "moment";
import { checkAPIStatus } from "./CheckAPIStatusService";


const DataService = {
  getTwins: async () => {
    const axiosResponse = await axios.get(`/digitalTwin`);

    return axiosResponse.data;
  },

  getsensor: async function (sensorId: number) {
    const axiosResponse = await axios.get(`/sensors/${sensorId}`);
    return axiosResponse.data;
  },

  getSensorsWithAlarmDetails: async (digitalTwinId: number) => {
    const axiosResponse = await axios.get(
      `/sensors/alarm_details/${digitalTwinId}`
    );
    return axiosResponse.data;
  },

  get_raw_info: async function (sensorId: number, daysToFetch: number) {
    //Seguinte, parça, essa lógica que vc vê em mês, dia, hora e minuto é pra adicionar 0 zero à esquerda em números menores que 0
    const currentDate = moment();
    const currentYear = currentDate.year();
    //Mês vai de 0 a 11
    const currentMonth = ("0" + (currentDate.month() + 1)).slice(-2);
    const currentDay = ("0" + currentDate.date()).slice(-2);
    const currentHour = ("0" + currentDate.hour()).slice(-2);
    const currentMinute = ("0" + currentDate.minute()).slice(-2);

    const pastDate = moment().subtract(daysToFetch, "days");
    const pastYear = pastDate.year();
    //Mês vai de 0 a 11
    const pastMonth = ("0" + (pastDate.month() + 1)).slice(-2);
    const pastDay = ("0" + pastDate.date()).slice(-2);
    const pastHour = ("0" + pastDate.hour()).slice(-2);
    const pastMinute = ("0" + pastDate.minute()).slice(-2);
    const axiosResponse = await axios.get(
      `/di2windata/get_raw_info?ist0=${pastYear}&ist1=${pastMonth}&ist2=${pastDay}&ist3=${pastHour}&ist4=${pastMinute}&iend0=${currentYear}&iend1=${currentMonth}&iend2=${currentDay}&iend3=${currentHour}&iend4=${currentMinute}&sensor_source=ASA&dayofweek=1&sensorId=${sensorId}&gt=${pastYear}-${pastMonth}-${pastDay}T${pastHour}:${pastMinute}:00.0-0300&lt=${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}:00.0-0300`
    );
    checkAPIStatus(sensorId, axiosResponse.data[axiosResponse.data.length - 1]);

    return axiosResponse.data;
  },

  getRecomendations: function () {
    return;
  },

  getAlertsHours: async (id: any, hours) => {
    const response = await axios.get(`/alerts/hours/${id}?hours=${hours}`);
    return response.data;
  },

  getAlertsToday: async function (id: any) {
    const start = moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString();
    const end = moment().toISOString();
    const currentAlerts = await axios.get(
      `/alerts/today/${id}?start=${start}&end=${end}`
    );
    return currentAlerts.data.sort((a, b) => {
      return b.id - a.id;
    });
  },

  getAlertsByTwin: async function (id: any) {
    const start = moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString();
    const end = moment().toISOString();

    const currentAlerts = await axios
      .get(`/alerts?digital_twin_id=${id}&gt=${start}&lt=${end}`)
      .then((res) => {
        return res;
      });

    return currentAlerts.data.sort((a, b) => {
      return b.id - a.id;
    });
  },

  alertCheck: async function (id: any, userId: string) {
    const body = {
      userId: userId,
      acknowledged: true,
      acknowledged_time: new Date().toISOString(),
    };

    await axios.post(`/ack/${id}`, body);

    return true;
  },

  getUserByID: async function (id: number) {
    const user = await axios.get(`/users/${id}`);
    return user;
  },

  getGraphsByTwin: async function (id: number) {
    const graphs = await axios.get(`/digitalTwin/graphs/${id}`);

    return graphs;
  },

  getTwinNameById: async function (id: number) {
    const twinName = await axios.get(`/digitalTwin/${id}`);
    // console.log(twinName);
    return twinName;
  },

  getDigitalTwinEfficiencyInterval: async function (id: number) {
    const efficiencyInterval = await axios.get(
      `/digitalTwin/efficiencyInterval/${id}`
    );
    return efficiencyInterval;
  },

  getTwinsByCompany: async function (companyId: string) {
    const twins = await axios.get(`/digitalTwin/company/${companyId}`);
    return twins.data;
  },

  getCompanyTwinsStatus: async function (companyId: string) {
    const status = await axios.get(`companies/status/${companyId}`);
    return status.data;
  },

  getCompanyInfo: async function (companyId: string) {
    const info = await axios.get(`/companies/${companyId}`);
    return info;
  },
  
  setUserProfile: async function (user: any) {
    const response = await axios.put(`/users/${user.id}`, user);
    return response;
  },

  setCompanyLogo: async function (company: any,index: number) {
    // console.log(company);
    const response = await axios.put(`/companies/${company.companies[index].id}`,company);
    return response;
  }
};

export default DataService;
