import "./SensorDetailsCard.css";
import LocalDataService from "../../../Services/LocalDataService";
import { useState } from "react";
import DeleteConfirmationDialog from "../../DeleteConfirmatioDialog/DeleteConfirmationDialog";
import EditSensorForm from "../../Forms/NewSensorForm/editSensorForm";

const SensorDetailsCard = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);

  const handleDelete = async () => {
    setModal(true);
  };

  const handleAction = async (event: any) => {
    const { name } = event.target;

    if (name === "confirm") {
      await LocalDataService.deleteSensor(props.sensor.id);
      props.handleResponse();
    } else {
      setModal(false);
    }
  };

  return (
    <div className="SensorDetailsCard" style={{ height: "calc(100% - 300px)" ,overflow: 'auto'}}>
      <EditSensorForm
        sensor={props.sensor}
        onClickDelete={handleDelete}
        handleResponse={props.handleResponse}
        cancel={props.cancel}
      />
      {modal && (
        <DeleteConfirmationDialog
          text="Certeza que quer deletar?"
          handleAction={handleAction}
        />
      )}
      {/* <p>{props.sensor.name}</p>
      <p>{props.sensor.number}</p>
      <p>{props.sensor.type}</p>
      <p>{props.sensor.variable}</p>
      <p>{props.sensor.variable_type}</p>
      <p>{props.sensor.unit}</p>
      <p>{props.sensor.resolution}</p>
      <p>{props.sensor.granularity}</p>*/}
      {/* <button className="deleteButton" onClick={handleDelete}>delete</button>  */}
    </div>
  );
};
export default SensorDetailsCard;
