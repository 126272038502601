import { useCallback, useEffect, useState } from "react";
import "./ActivationView.css";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../router/paths";
import AuthService from "../../Services/AuthService";
import loginBackground from "../../Assets/Images/loginBackground2.png";
import { Dialog } from "@mui/material";


const ActivationView = () => {

  const [open, setOpen] = useState(false);
  const [error , setError] = useState<string>("");
  const [response , setResponse] = useState<string>("");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [errorPassword , setErrorPassword] = useState<string>("");
  const [responsePassword , setResponsePassword] = useState<string>("");

  const [password , setPassword] = useState<string>("");
  const [passwordConfirm , setPasswordConfirm] = useState<string>("");

  const navigate = useNavigate();
  const token = useParams().token;

  const userActivated = useCallback(() => {
    setResponse("Conta ativada");
    setTimeout(() => {
      setResponse("");
      setOpen(false);
      navigate(paths.LOGIN_SCREEN);
      
    }, 3000);
  }, [navigate]);

  const userNotActivated = useCallback(() => {
    setError("Conta não ativada");
    setTimeout(() => {
      setError("");
      setOpen(false);
      navigate(paths.LOGIN_SCREEN);
      
    }, 3000);
  }, [navigate]);

  const activation = useCallback(async ( ) => {
    setOpen(true);
    try {
      await AuthService.activationCode(token);
      
      userActivated();
      
    } catch (error) {
      userNotActivated()
    }
    
  }, [token,userActivated,userNotActivated]);

  const changePassword = useCallback(async ( ) => {
    
    try {

      
      token && await AuthService.resetPassword( password,passwordConfirm, token)

      

      setResponsePassword("Senha alterada com sucesso");
      setTimeout(() => {
        setResponsePassword("");
        setOpenChangePassword(false);
       activation();
        
      }, 1000);

    } catch (error) {
      setErrorPassword("Erro ao alterar senha");
      setTimeout(() => {
        setErrorPassword("");
        setOpenChangePassword(false);
        navigate(paths.LOGIN_SCREEN);
        
      }, 3000);
    }
  }, [navigate, passwordConfirm, token, activation, password]);

  useEffect(() => {
    setTimeout(() => setOpenChangePassword(true), 500);
    
  }, []);
  

 
 
  return (
    <div className="ActivationContainer" style={{
      backgroundImage: `url(${loginBackground}) `,
      backgroundSize: "cover",
    }}>
      <Dialog open={openChangePassword}>
        <div style={{ textAlign: "center",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         margin: "20px",
         }}>
             <p style={{fontSize:"2rem"}}>Criando senha de acesso</p>
            <input type="password" placeholder="Insira a chave de acesso" style={{marginBottom:"10px", padding:"10px", borderRadius:"4px",width:"300px" }} onChange={(e) => setPassword(e.target.value)} />
            
            <input type="password" placeholder="Insira nova senha" style={{marginBottom:"10px", padding:"10px", borderRadius:"4px",width:"300px" }}  onChange={(e) => setPasswordConfirm(e.target.value)} />

            
          

            <div style={{display:"flex", justifyContent:"center"}}>
            <button style={{marginRight:"10px"}} onClick={() => {setOpenChangePassword(false); navigate(paths.LOGIN_SCREEN);}}>Cancelar</button>
            <button style={{marginLeft:"10px"}} onClick={() => {changePassword();}}>Confirmar</button>
            </div>
            {error !== "" && <p style={{color:"red"}}>{errorPassword}</p>}
            {response !== "" && <p style={{color:"green"}}>{responsePassword}</p>}
        </div>
      </Dialog>
      <Dialog open={open}>
        <div style={{ textAlign: "center",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         margin: "20px",
         }}>
            <p style={{fontSize:"2rem"}}>Ativação de conta...</p>
            {error !== "" && <p style={{color:"red"}}>{error}</p>}
            {response !== "" && <p style={{color:"green"}}>{response}</p>}
        </div>
      </Dialog>
    </div>
  );
};
export default ActivationView;
