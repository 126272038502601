import EditUserForm from "../../Forms/NewUserForm/EditUserForm";

import LocalDataService from "../../../Services/LocalDataService";
import DeleteConfirmationDialog from "../../DeleteConfirmatioDialog/DeleteConfirmationDialog";
import { useEffect, useState } from "react";


const GraphDetailsCard = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);

  const [companie, setCompanie] = useState<any>({
    name: "",
    id: "",
  });


    useEffect(() => {
      if (props.selected) {
        LocalDataService.getCompany(props.selected).then((data) => {
          setCompanie(data);
        });
      }
    } , [props.selected]);
  const handleDelete = async () => {
    setModal(true);
  };

  const handleAction = async (event: any) => {
    const { name } = event.target;

    if (name === "confirm") {
      // Atualize para chamar o método de remoção da relação
      await LocalDataService.removeUserRelation(props.user.id, props.selected);
      setModal(false);
      props.handleResponse();
    } else {
      setModal(false);
      props.handleResponse();
    }
  };

  return (
    <div className="GraphDetailsCard">
      <EditUserForm
        user={props.user}
        onClickDelete={handleDelete}
        handleResponse={props.handleResponse}
        cancel={props.cancel}
        activateUser={props.activateUser}
      />
      {modal && (
        <DeleteConfirmationDialog
          text={`Tem certeza que quer remover  o usuário ${props.user.name} de ${companie.name}?`}
          handleAction={handleAction}

        />
      )}
    </div>
  );
};
export default GraphDetailsCard;
