// chartRefsManager.ts
import ReactEcharts from "echarts-for-react";
import { RefObject } from "react";
import { syncChartsTooltip, syncChartsZoom } from "./syncGraphs"; // Adjust the path

class ChartRefsManager {
  private refs: { [key: number]: RefObject<ReactEcharts> } = {};

  setRef(index: number, ref: RefObject<ReactEcharts>) {
    this.refs = { ...this.refs, [index]: ref }; // Replace the entire refs object
    this.updateCursorSync(); // Automatically handle cursor sync
  }

  getRefs() {
    return this.refs;
  }

  private updateCursorSync() {
    const allRefs = Object.values(this.refs);
    if (allRefs.length > 0 && allRefs.every((ref) => ref.current !== null)) {
      syncChartsZoom(allRefs, "datazoom");
      syncChartsTooltip(allRefs, "mousemove");
    }
  }
}

const chartRefsManager = new ChartRefsManager();
export default chartRefsManager;
